import React from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import CustomForm from 'react-validation/build/form';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import Form from '../shared/form-control'
import Table from 'react-bootstrap/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Select from 'react-dropdown-select';
import { withStyles } from '@material-ui/core/styles'
import { required } from '../../helpers/form-validation';
import "./contact.scss";
import { LOCATION } from '../../helpers/constants';
import { CreateContactAction, GetContactsAction, updateContactAction } from '../../actions/contact-action';
import store from '../../store';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask'
import CharacterCounter from '../../containers/character-counter/character-counter';
import { GetCategoryAction } from '../../actions/category-action';

const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#A91F2E',
            fontSize: '0.7365728900255755rem',
            color: '#A91F2E',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: '#A91F2E',
            fontSize: '0.7365728900255755rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: '0.5728900255754475rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        }
    }
});
class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 1,
            toggle: 1,
            page: 0,
            rowsPerPage: 20,
            lastSearch: '',
            contact: {
                // name: '',
                firstName: '',
                lastName: '',
                phone: '',
                address: '',
                location: '',
                organizationName: '',
                categoryId: '',
                mobile: '',
            },
            searchValue: '',
            contacts: []
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.updateContact = this.updateContact.bind(this);

        this.handleChangePage = this.handleChangePage.bind(this);
        this.changeToggle = this.changeToggle.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeLastSearch = this.changeLastSearch.bind(this);
        this.changePerPage = this.changePerPage.bind(this);

        store.dispatch(GetCategoryAction());
    }

    changeToggle(v) {
        this.setState({ toggle: v })
    }
    changePage(v) {
        this.setState({ page: v })
    }
    changeLastSearch(v) {
        this.setState({ lastSearch: v })
    }
    changePerPage(v) {
        this.setState({ rowsPerPage: v })
    }

    handleChangePage(event, newPage) {
        console.log('newPagen', newPage);
        store.dispatch(GetContactsAction(`query=${this.state.searchValue}&page=${Number(newPage + 1)}`))
        this.setState({ page: newPage });
    }

    componentDidMount() {
        store.dispatch(GetContactsAction(`query=${this.state.searchValue}&page=` + Number(this.state.page + 1)))
        this.setState({ contacts: this.props.contacts })
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.contacts) !== JSON.stringify(this.props.contacts)) {
            this.setState({ contacts: this.props.contacts })
        }

        if (JSON.stringify(prevProps.contact) !== JSON.stringify(this.props.contact)) {
            this.setState({ contact: {} })
            for (const key in this.form.state.byId) {
                this.form.hideError({ id: key })
            }
            this.setState({ freezeBtn: false })
            store.dispatch(GetContactsAction(`query=` + this.state.searchValue + `&page=` + Number(this.state.page + 1)))
        }

        if (prevProps.categories !== this.props.categories) {
            this.setState({ categories: this.props.categories });
        }
    }

    handleChange(e) {
        let contact = this.state.contact;
        contact[e.target.name] = e.target.value;
        this.setState({ contact });
    }

    handleSelect(value, propName) {
        let contact = this.state.contact;
        if (value[0]) {
            contact[propName || value[0].name] = value[0].value;
            this.setState({ contact });
        }
    }

    handleOnSubmit(e) {
        e.preventDefault();
        this.form.validateAll()
        if (this.form.getChildContext()._errors.length === 0) {
            this.setState({ freezeBtn: true })
            if (this.state.contact.id)
                store.dispatch(updateContactAction(this.state.contact)) //set in session storage
            else
                store.dispatch(CreateContactAction(this.state.contact)) //set in session storage
        }
    }

    getCategoriesOptions() {
        return [
            { value: '', label: 'Select Category', disabled: true },
            ...(this.state.categories || [])
                .map(category => ({
                    value: category.id,
                    label: category.name
                }))
        ];
    }

    onChange(e) {
        this.setState({ searchValue: e.target.value }, () => {
            this.onClick()
        })
    };

    onClick() {
        if (this.state.searchValue) {
            let result = this.props.contacts.filter((elem) => {
                return (elem.firstName && elem.firstName.toLowerCase().includes(this.state.searchValue)) || (elem.lastName && elem.lastName.toLowerCase().includes(this.state.searchValue)) || (elem.organizationName && elem.organizationName.toLowerCase().includes(this.state.searchValue))
            })
            this.setState({ contacts: result })
        } else {
            this.setState({ contacts: this.props.contacts })
        }
    }
    updateContact(contact) {
        this.setState({ contact })
    }

    ContactTable() {
        return (
            (
                this.state.contacts.length &&
                <Table className="text-center">
                    <thead>
                        <tr>
                            <th className="bg-dark-grey">Contact Name</th>
                            <th className="bg-dark-grey">Organization Name</th>
                            <th className="bg-dark-grey">Number</th>
                            <th className="bg-dark-grey">Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.contacts.map((elem, index) => (
                                <tr style={{ cursor: "pointer" }} onClick={() => this.updateContact(elem)}>
                                    <td className={(index % 2) ? 'bg-dark-grey' : ''} >{`${elem.firstName || 'N/A'} ${elem.lastName || 'N/A'}`}</td>
                                    <td className={(index % 2) ? 'bg-dark-grey' : ''} >{elem.organizationName}</td>
                                    <td className={(index % 2) ? 'bg-dark-grey' : ''} >{elem.phone}</td>
                                    <td className={(index % 2) ? 'bg-dark-grey' : ''} >{elem.location}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            ) || (<h6 class="text-center">No Contacts Found</h6>)
        );
    }
    render() {
        const { classes } = this.props;
        return (
            <div className="contact-section full-width">
                <div className="Main-search mt-4">
                    <TextField className={classes.root} id="outlined-search"
                        placeholder='Search Name, Mobile or DOB'
                        label="Search"
                        value={this.props.search}
                        onChange={(e) => this.onChange(e)}
                        onKeyPress={(e) => e.key === 'Enter' ? this.onClick() : console.log(e.key)}
                        type="search"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={this.onClick}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button variant="primary" onClick={this.onClick} type="submit" className="search-btn">Search</Button>
                </div>
                <Card className="pt-2">
                    <Row>
                        <Col md={12} style={{ maxHeight: '30vh', overflowY: 'auto' }} className="align-content-center">
                            <TableContainer component={Paper}>
                                <Table className="text-center">
                                    <thead>
                                        <tr>
                                            <th className="bg-dark-grey">Contact Name</th>
                                            <th className="bg-dark-grey">Organization Name</th>
                                            <th className="bg-dark-grey">Number</th>
                                            <th className="bg-dark-grey">Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.contacts.map((elem, index) => (
                                                <tr style={{ cursor: "pointer" }} onClick={() => this.updateContact(elem)} key={"contact" + index}>
                                                    <td className={(index % 2) ? 'bg-dark-grey' : ''} >{`${elem.firstName || 'N/A'} ${elem.lastName || 'N/A'}`}</td>
                                                    <td className={(index % 2) ? 'bg-dark-grey' : ''} >{elem.organizationName}</td>
                                                    <td className={(index % 2) ? 'bg-dark-grey' : ''} >{elem.phone}</td>
                                                    <td className={(index % 2) ? 'bg-dark-grey' : ''} >{elem.location}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </TableContainer>
                            {<TablePagination
                                labelRowsPerPage=''
                                rowsPerPageOptions={[]}
                                component="div"
                                count={this.props.totalContacts}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                            />}
                        </Col>
                    </Row>
                </Card>

                <Card>
                    <Card.Header className="text-center">{this.state.contact.firstName ? ('Update ' + this.state.contact.firstName + ' ' + this.state.contact.lastName) : ('Create Contact')}</Card.Header>
                    <Card.Body>
                        <CustomForm ref={c => { this.form = c }} onSubmit={this.handleOnSubmit}>
                            <Form.Row className="d-flex align-items-center bg-grey p-3">
                                <Col md={5}>
                                    <Row>
                                        <Col md={5}>
                                            <Form.Label className="pl-3"> First Name</Form.Label>
                                        </Col>
                                        <Col md={7} className="pl-1">
                                            <CharacterCounter>
                                                <Form.Control type='text' validations={[required]} name="firstName" value={this.state.contact.firstName ? this.state.contact.firstName : ''} placeholder="First Name" onChange={this.handleChange} className="form-field-border custom-width" maxLength={255} />
                                            </CharacterCounter>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={7}>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label className="text-label">Last Name</Form.Label>
                                        </Col>
                                        <Col md={8} className="pl-1">
                                            <CharacterCounter>
                                                <Form.Control type='text' validations={[required]} name="lastName" value={this.state.contact.lastName ? this.state.contact.lastName : ''} placeholder="Last Name" onChange={this.handleChange} className="form-field-border custom-width" maxLength={255} />
                                            </CharacterCounter>
                                        </Col>
                                    </Row>
                                </Col>
                            </Form.Row>
                            <Form.Row className="d-flex align-items-center p-3">
                                <Col md={5}>
                                    <Row>
                                        <Col md={5}>
                                            <Form.Label className="pl-3">Organization Name</Form.Label>
                                        </Col>
                                        <Col md={7} className="pl-1">
                                            <CharacterCounter>
                                                <Form.Control type='text' validations={[required]} name="organizationName" value={this.state.contact.organizationName ? this.state.contact.organizationName : ''} placeholder="Organization Name" onChange={this.handleChange} className="form-field-border custom-width" maxLength={255} />
                                            </CharacterCounter>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={7}>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label className="text-label">Location</Form.Label>
                                        </Col>
                                        <Col md={8}>

                                            <Select className="selection-select arrow-down custom-width font-bold"
                                                options={LOCATION}
                                                required
                                                values={LOCATION.filter(elem => elem.value === this.state.contact.location)}
                                                name="location"
                                                onChange={(value) => this.handleSelect(value)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Form.Row>
                            <Form.Row className="d-flex align-items-center bg-grey p-3">
                                <Col md={5}>
                                    <Row>
                                        <Col md={5}>
                                            <Form.Label className="pl-3">Address </Form.Label>
                                        </Col>
                                        <Col md={7} className="pl-1">
                                            <CharacterCounter>
                                                <Form.Control type='text' name="address" validations={[required]} value={this.state.contact.address ? this.state.contact.address : ''} placeholder="address" onChange={this.handleChange} className="form-field-border custom-width" maxLength={255} />
                                            </CharacterCounter>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={7}>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label className="text-label">Phone No</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <InputMask alwaysShowMask={false} placeholder="Phone no" className="form-field-border custom-width form-control" value={this.state.contact.phone ? this.state.contact.phone : ''} name="phone" onChange={this.handleChange} type="text" mask="99 9999 9999" maskChar="" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Form.Row>
                            <Form.Row className="d-flex align-items-center p-3">
                                <Col md={5}>
                                    <Row>
                                        <Col md={5}>
                                            <Form.Label className="pl-3">Category </Form.Label>
                                        </Col>
                                        <Col md={7} className="pl-1">
                                            <Select className="selection-select arrow-down custom-width"
                                                placeholder="Contact Category"
                                                options={this.getCategoriesOptions()}
                                                values={(this.getCategoriesOptions()).filter(category => category.value === this.state.contact.categoryId)}
                                                name="categoryId"
                                                onChange={(value) => this.handleSelect(value, 'categoryId')}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={7}>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label className="text-label">Mobile</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <InputMask alwaysShowMask={false} placeholder="Mobile" className="form-field-border custom-width form-control" value={this.state.contact.mobile ? this.state.contact.mobile : ''} name="mobile" onChange={this.handleChange} type="text" mask="99 9999 9999" maskChar="" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Form.Row>
                            <div style={{ float: 'right' }} className="d-flex align-items-center p-3 form-row Main-search">
                                {this.state.contact.id && <button type="button" onClick={() => this.setState({ contact: {} })} className="main-btn default-btn btn btn-primary ali"> Cancel </button>}
                                <button type="submit" disabled={this.state.freezeBtn} className="btn-save search-btn btn btn-primary"> {this.state.contact.id ? 'Update' : 'Save'} </button>
                            </div>
                            <div className="d-flex align-items-center p-3 form-row Main-search">
                            </div>
                        </CustomForm>
                    </Card.Body>
                </Card>
            </div>

        )
    }
}
const stateMap = (state) => {
    return {
        contact: state.contact.contact,
        contacts: state.contact.contacts,
        totalContacts: state.contact.totalContacts,
        categories: (state.category && state.category.categories || []),
    };
};
export default connect(stateMap)((withStyles(styles, { withTheme: true })(ContactForm)));
