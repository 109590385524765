import React from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { CLIENT_OBSERVATION, IS_CLIENT_OBSERVATION, FULL_TIME_FORMAT, DATE_FORMAT } from "../../helpers/constants";

import CancelIcon from '@material-ui/icons/Cancel';
import moment from "moment"
import "../diversion-centre/diversion-centre.scss";
import { connect } from "react-redux";
import store from '../../store';
import CharacterCounter from '../../containers/character-counter/character-counter';


class ClientObservationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cTime: '',
            propsLen: 0,
            observations: [{
                clientCheck: moment().format().trim(),
                clientAwake: 1,
                clientStatusChange: 2,
                statusComments: ''
            }]
        };
        this.addMore = this.addMore.bind(this);
        this.removeMedical = this.removeMedical.bind(this);
        this.onChange = this.onChange.bind(this);

        this.options4 = [
            { label: "Awake", value: 1 },
            { label: "Sleep", value: 2 },
        ];

        this.options5 = [
            { label: "Yes", value: 1 },
            { label: "No", value: 2 },
        ];
    }

    handleDropDown(e, name, index) {
        let observations = this.state.observations;
        if (e[0]) {
            observations[index][name] = e[0].value;

            this.setState({ observations }, () => {
                store.dispatch({
                    type: CLIENT_OBSERVATION,
                    payload: this.state.observations
                });
                store.dispatch({
                    type: IS_CLIENT_OBSERVATION,
                    payload: true
                });
            })
        }
    }

    onChange(e, loc) {
        let name = e.target.name
        let stateData = this.state
        stateData.observations[loc][name] = e.target.value
        this.setState({ ...stateData }, () => {
            store.dispatch({
                type: CLIENT_OBSERVATION,
                payload: this.state.observations
            });
            store.dispatch({
                type: IS_CLIENT_OBSERVATION,
                payload: true
            });
        });
    }

    addMore() {
        let observationsObjs = this.state.observations;
        observationsObjs.push({
            clientCheck: moment().format().trim(),
            clientAwake: 1,
            clientStatusChange: 2,
            statusComments: ''
        })
        this.setState({ observations: observationsObjs })
    }

    removeMedical(index) {
        let observations = this.state.observations
        observations.splice(index, 1);
        this.setState({ observations })
    }

    componentDidMount() {
        if (this.props.medObs) {
            let allProps = this.props.medObs;

            const observations = ((allProps.ClientObservationForms && allProps.ClientObservationForms.length) ? (allProps.ClientObservationForms) : [{
                clientCheck: moment().format().trim(),
                clientAwake: 1,
                clientStatusChange: 2,
                statusComments: ''
            }]);

            observations.forEach(observation => {
                if (!observation.statusComments) observation.statusComments = '';
            });

            this.setState({
                observations,
                propsLen: (allProps.ClientObservationForms && allProps.ClientObservationForms.length) ? allProps.ClientObservationForms.length : 0,
                cTime: moment().format(FULL_TIME_FORMAT).trim()
            }, () => {

                store.dispatch({
                    type: CLIENT_OBSERVATION,
                    payload: this.state.observations
                });
                store.dispatch({
                    type: IS_CLIENT_OBSERVATION,
                    payload: true
                });

            })
        }
    }


    render() {
        return (
            <>
                {this.state.observations.map((observation, index) => (
                    <React.Fragment key={"Observation" + index}>
                        <Col md={11}>
                            <div className="client-record">

                                {index == 0 && <h6>CLIENT OBSERVATION <br></br>RECORD</h6>}
                                <Row className="d-flex align-items-center">
                                    <Col className="observation-colum">
                                        {index == 0 && <Form.Label className="observation-label">Did you check on them?</Form.Label>}
                                        <Form.Label className="observation-label form-fields text-center pt-0"><span style={{ color: "#b3b3b3" }}>{moment(observation.clientCheck).format(DATE_FORMAT).trim()}</span> &nbsp; {moment(observation.clientCheck).format(FULL_TIME_FORMAT).trim()}</Form.Label>
                                        {/* <Form.Control name="clientCheck" type="time" disabled value={moment(observation.clientCheck).format(FULL_TIME_FORMAT).trim()} placeholder="Enter Time" className="form-fields observation-input text-center pt-0" /> */}
                                    </Col>
                                    <Col>
                                        {index == 0 && <Form.Label className="observation-label">Were they awake or asleep?</Form.Label>}
                                        <Select className="selection-select observation-input arrow-down custom-width"
                                            placeholder=""
                                            disabled={index <= (this.state.propsLen - 1)}
                                            options={this.options4}
                                            values={[...this.options4.filter(elem => elem.value == (eval(observation.clientAwake) ? eval(observation.clientAwake) : ''))]}
                                            name="clientAwake"
                                            onChange={(e) => this.handleDropDown(e, 'clientAwake', index)}
                                        />
                                    </Col>
                                    <Col>
                                        {index == 0 && <Form.Label className="observation-label text-center">Status Change?</Form.Label>}
                                        <Select className="selection-select observation-input arrow-down custom-width"
                                            placeholder="Yes"
                                            disabled={index <= (this.state.propsLen - 1)}
                                            options={this.options5}
                                            values={[...this.options5.filter(elem => elem.value == (eval(observation.clientStatusChange) ? eval(observation.clientStatusChange) : ''))]}
                                            name="clientStatusChange"
                                            onChange={(e) => this.handleDropDown(e, 'clientStatusChange', index)}
                                        />
                                    </Col>
                                    <Col>
                                        {index == 0 && <Form.Label className="observation-label d-block">Comments<br /><small>(Mandatory when status changed)</small></Form.Label>}
                                        <CharacterCounter>
                                            <Form.Control name="statusComments"
                                                value={observation.statusComments}
                                                disabled={index <= (this.state.propsLen - 1)}
                                                onChange={(e) => this.onChange(e, index)} className="form-fields observation-input text-center pt-0"
                                                placeholder="Status Comments Here..."
                                                maxLength={255} />
                                        </CharacterCounter>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={1}>
                            {this.state.observations.length !== 1 && !observation.id && <CancelIcon onClick={() => this.removeMedical(index)} className="float-right"></CancelIcon>}
                        </Col>
                        <Col md={12}>
                            <div className="btn-sec full-width text-left form-btn">
                                {index === (this.state.observations.length - 1) &&
                                    <button type="button" onClick={this.addMore} disabled={this.props.disabled} className="main-btn default-btn btn-width btn btn-primary">
                                        ADD MORE
                                    </button>
                                }
                            </div>
                        </Col>
                    </React.Fragment>
                ))
                }
            </>
        )
    }
}

const stateMap = (state) => {
    return {
        isClientObservation: state.clientObservation.isClientObservation,
        clientObservations: state.clientObservation.clientObservations,
    };
};

export default connect(stateMap)(ClientObservationForm);