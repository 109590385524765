import React from "react";
import Header from "../../components/shared/header/header";
import Search from "../../components/search/search";
import BasicTable from "../../components/table/table";
import ClientForm from "../../components/client-form/client-form";
import UpdateForm from "../../components/client-form/update-form";
import ThreeTabs from "../../components/three-tabs/three-tabs";
import Sidebar from "../../components/shared/sidebar/sidebar";
import { connect } from "react-redux";
import { SearchClientsAction } from "../../actions/client-action";
import { DATE_FORMAT, DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";

class MainClientsContainer extends React.Component {
    constructor(props) {
        super(props)
        this.searchChanged = this.searchChanged.bind(this);
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
            search: '',
            clients: []
        }
    }
    searchChanged(search){
        this.setState({search})
    }
    componentDidUpdate(prevProps) {
        // if (JSON.stringify(prevProps.searchClients) !== JSON.stringify(this.props.searchClients)) {
        //     this.setState({ clients: this.props.searchClients })
        //     
        //     // toast('Event Partner Save Successfully', 'info')
        // }
        // if (JSON.stringify(prevProps.searchResult) !== JSON.stringify(this.props.searchResult)) {
        //     this.setState({ clients: this.props.searchClients })
        //     
        //     // toast('Event Partner Save Successfully', 'info')
        // }
    }
    render() {
        return (
            <div className="client-content" style={{ display: 'flex' }}>
                <Header handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
                    {/* <Search search={this.props.search} onClick={this.onClick} onChange={this.handleChange} {...this.props} /> */}
                    <Search search={this.props.search} from={'client'} searchChanged={this.searchChanged} {...this.props} />
                    {this.props.searchClients && <BasicTable search={this.state.search}  {...this.props} />}
                    {/* <ClientForm {...this.props} /> */}
                    {/* <UpdateForm {...this.props} /> */}
                    {/* <ThreeTabs {...this.props} /> */}
                </Sidebar>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {

        searchClients: state.client.searchClients,
        searchResult: state.client.searchResult,
        totalClients: state.client.totalClients,
    };
};
export default connect(stateMap)(MainClientsContainer);
