import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { TO_DASHBOARD, TO_LOGIN } from "../../../helpers/constants";
import Form from 'react-validation/build/form';
import "./verify-form.scss";
import { connect } from "react-redux";
import { setToken, setUser, toast } from "../../../helpers/utility";
import store from "../../../store"
import { VerifyAction } from "../../../actions/user/login";
import InputMask from 'react-input-mask'
import qs from 'qs'


class VerifyForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.state = {
      show: false,
      redirect: '',
      code: '',
      value: [],
      verify: false

    }
  }
  handleChange(e) {
    
    this.setState({ code: e.target.value })
    // let user = this.state.user
    // user[e.target.name] = e.target.value;
    // this.setState({ user })
  }
  handleOnSubmit(e) {
    e.preventDefault();
    
    store.dispatch(VerifyAction({ encryptedCode: this.props.user.user.encryptedCode, toverify: this.state.code, token: this.props.user.user.token }, this.state.user)) //set in session storage
    this.setState({ verify: true })
  };
  componentDidMount() {
    if (!this.props.allowToVerify)
      this.props.history.push(TO_LOGIN)
    let queryString = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true })
    
    if (Object.keys(queryString).length)
      this.setState({ redirect: queryString.redirect })
    // if (this.props.user.isLoggedIn) {
    //   if (queryString.redirect) {
    //     this.props.history.push(queryString.redirect)
    //   } else {
    //     this.props.history.push(TO_DASHBOARD)
    //   }
    // }
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.user) !== JSON.stringify(prevProps.user))
      if (this.state.verify === true) {
        toast()
        
        setToken(this.props.user.user.token)
        setUser(this.props.user.user.user)
        // window.location.reload();
        if (this.state.redirect) {
          
          this.props.history.push(this.state.redirect)
        }
        else {
          
          this.props.history.push(TO_DASHBOARD)
        }
      }
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <div className="verify-form">
        <div className="container">
          <Row className="align-items-center content-row">
            <Col md={6}>
              <img
                className="mb-4 logo-img"
                src={require("../../../assets/images/logo.svg")}
                alt="logo"
              />
            </Col>
            <Col md={6}>
              <div className="register-box">
                <h3 className="text-center mb-3">Verify It’s You</h3>
                <p className="text-center pl-3 pr-3">
                  We have sent a verification code to you via sms. Enter the 6 digit code in the field below.
                          </p>
                <Form ref={c => { this.form = c }} onSubmit={this.handleOnSubmit}>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Row className="pl-4 pr-4">
                        <InputMask alwaysShowMask={true} className="verify-field" value={this.state.value[0]} name="code" onChange={this.handleChange} type="text" fullWidth mask="9 9 9 9 9 9" maskChar="_" />
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12} className="register-btn">
                      <Button variant="primary btn-green" type="submit" size="lg" block> SUBMIT</Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={12} className="register-btn">
                      <Button variant="primary btn-light-grey" type="submit" size="lg" block> RESEND CODE</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>

    );
  }
}
const stateMap = (state) => {
  return {
    user: state.user,
    global: state.global,
    allowToVerify: state.user.allowToVerify
  };
};
export default connect(stateMap)(VerifyForm);
