
import { http } from "./http";
import { CREATE_DIVERSION_CENTER } from "./constants";

export const createDiversionCenter = async (data) => {
    return http.post(CREATE_DIVERSION_CENTER, data)
}
export const getDiversionCenter = async () => {
    return http.get(CREATE_DIVERSION_CENTER)
}
export const getDiversion = async (id) => {
    return http.get(CREATE_DIVERSION_CENTER + '/' + id)
}
export const updateDiversionCenter = async (data) => {
    return http.put(CREATE_DIVERSION_CENTER + '/' + data.id, data)
}

