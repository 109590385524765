/**
 * For some unknown reason this component refuses to correctly set the checked state of a number of tick boxes correctly
 * when the state updates. As a workaround the component has been forced to rebuild by using  key={Math.random()} which is 
 * less than ideal. No other solution is known to ensure that the checkbox state updates when the value updates
 * 
 * See: https://stackoverflow.com/questions/29808636/when-giving-unique-keys-to-components-is-it-okay-to-use-math-random-for-gener
 */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from 'react-dropdown-select';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal'
import { required } from '../../helpers/form-validation';
import { MEDICAL_OBSERVATION, IS_MEDICAL_OBSERVATION } from "../../helpers/constants";
import CharacterCounter from '../../containers/character-counter/character-counter';

import CancelIcon from '@material-ui/icons/Cancel';

import "../diversion-centre/diversion-centre.scss";
import { connect } from "react-redux";
import store from '../../store';

import moment from "moment"

class ClientMedicalRiskForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAmbulance: false,
            isAggression: false,
            isOnMedication: false,
            medicationName: '',
            isArrangements: 0,
            medicationDate: '',
            medicationTime: '',
            risks: [{
                symptoms: 0
            }]
        };
        this.addMore = this.addMore.bind(this);
        this.removeMedical = this.removeMedical.bind(this);
        this.onChange = this.onChange.bind(this);

        this.options2 = [
            { label: '', value: '', selected: true, disabled: true },
            { label: "1. Cannot sit, stand or walk properly", value: 1 },
            { label: "2. Unconscious and/or unable to be woken", value: 2 },
            { label: "3. Becoming more vague and less sensible over time", value: 3 },
            { label: "4. Serious injury, including head injury, bleeding in particular from the mouth or ears", value: 4 },
            { label: "5. Other signs of injuries such as cuts or bruises", value: 5 },
            { label: "6. Cannot stop vomiting, or vomits up blood", value: 6 },
            { label: "7. Epileptic fit or fainting", value: 7 },
            { label: "8. Asthma attack", value: 8 },
            { label: "9. Difficult or noisy breathing, including cracking or wheezing", value: 9 },
            { label: "10. Chest, head, stomach, leg, arm pain", value: 10 },
            { label: "11. Diarrhea", value: 11 },
            { label: "12. Showing signs of recently taking drugs and/or inhaled fumes", value: 12 },
            { label: "13. Withdrawal symptoms – especially from alcohol (anxious, aggressive, irritable, cannot sleep, tremor’s)", value: 13 },
        ];
    }

    handleDropDown(e, name, index) {
        let risks = this.state.risks
        if (e[0]) {
            risks[index][name] = e[0].value

            this.setState({ risks }, () => {
                store.dispatch({
                    type: MEDICAL_OBSERVATION,
                    payload: this.state.risks
                });
                store.dispatch({
                    type: IS_MEDICAL_OBSERVATION,
                    payload: true
                });
            })
        }
        // 
    }

    onChange(e, loc, id) {
        this.props.onFieldChange(e, loc);
        let name = e.target.name;
        let stateData = this.state;

        if (loc === 'check') {
            stateData[name] = eval(e.target.value);
            this.setState({ ...stateData });
        } else {
            stateData[name] = e.target.value;
            this.setState({ ...stateData });
        }
    }

    addMore() {
        let risksObjs = this.state.risks
        risksObjs.push({
            symptoms: 0,
        })
        this.setState({ risks: risksObjs })
    }

    removeMedical(index) {
        let risks = this.state.risks
        risks.splice(index, 1);
        this.setState({ risks })
    }

    componentDidMount() {
        if (this.props.medObs) {
            let allProps = this.props.medObs;
            this.setState({
                isAmbulance: allProps.isAmbulance,
                isOnMedication: allProps.isOnMedication,
                isAggression: allProps.isAggression,
                medicationName: allProps.medicationName || '',
                isArrangements: allProps.isArrangements,
                medicationDate: allProps.medicationDate || '',
                medicationTime: allProps.medicationTime || '',
                risks: (allProps.MedicalObservations && allProps.MedicalObservations.length) ? allProps.MedicalObservations : [{ symptoms: '' }]
            }, () => {

                store.dispatch({
                    type: MEDICAL_OBSERVATION,
                    payload: this.state.risks
                });
                store.dispatch({
                    type: IS_MEDICAL_OBSERVATION,
                    payload: true
                });

            })
        }
    }


    render() {
        return (
            <>
                <Col md={12} className="p-0">
                    <Row className={"d-flex align-items-center p-2 bg-grey"}>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">
                                        Does an ambulance need to be contacted?
                                    </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control key={Math.random()} checked={!this.state.isAmbulance} disabled={this.props.disabled} value={false} onChange={(e) => this.onChange(e, 'check')} name={"isAmbulance"} type="radio" id={"community-checkbox153"} className="custom-checkbox" />
                                    <label htmlFor={"community-checkbox153"}>No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={6}>
                                    <Form.Control key={Math.random()} checked={this.state.isAmbulance} disabled={this.props.disabled} value={true} onChange={(e) => this.onChange(e, 'check')} name={"isAmbulance"} type="radio" id={"community-checkbox154"} className="custom-checkbox" />
                                    <label htmlFor={"community-checkbox154"}>Yes</label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className={"d-flex align-items-center p-2 bg-grey"}>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">
                                        Is the client on any medication?
                                    </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control key={Math.random()} checked={!this.state.isOnMedication} disabled={this.props.disabled} value={false} onChange={(e) => this.onChange(e, 'check')} name={"isOnMedication"} type="radio" id={"community-checkbox151"} className="custom-checkbox" />
                                    <label htmlFor={"community-checkbox151"}>No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control key={Math.random()} checked={this.state.isOnMedication} value={true} disabled={this.props.disabled} onChange={(e) => this.onChange(e, 'check')} name={"isOnMedication"} type="radio" id={"community-checkbox152"} className="custom-checkbox" />
                                    <label htmlFor={"community-checkbox152"}>Yes. If yes name the medication.</label>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <CharacterCounter>
                                            <Form.Control disabled={(!this.state.isOnMedication || this.props.disabled)} value={this.state.medicationName} onChange={(e) => this.onChange(e)} name={"medicationName"} as="textarea" rows={2} maxLength={255} />
                                        </CharacterCounter>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className={"d-flex align-items-center p-2 bg-grey"}>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Do arrangements need to be made for medication to be provided to the client?</Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control key={Math.random()} checked={!this.state.isArrangements || this.state.isArrangements == 0} disabled={this.props.disabled} value={0} onChange={(e) => this.onChange(e, 'check')} name={"isArrangements"} type="radio" id={"community-checkbox257"} className="custom-checkbox" />
                                    <label htmlFor={"community-checkbox257"}>No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={4}>
                                    <Form.Control key={Math.random()} checked={this.state.isArrangements == 1} value={1} disabled={this.props.disabled} onChange={(e) => this.onChange(e, 'check')} name={"isArrangements"} type="radio" id={"community-checkbox258"} className="custom-checkbox" />
                                    <label htmlFor={"community-checkbox258"}>Unknown</label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control key={Math.random()} checked={this.state.isArrangements == 2} value={2} disabled={this.props.disabled} onChange={(e) => this.onChange(e, 'check')} name={"isArrangements"} type="radio" id={"community-checkbox259"} className="custom-checkbox" />
                                    <label htmlFor={"community-checkbox259"}>Yes. If yes, arrange for client to be taken to a doctor.</label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className={"d-flex align-items-center p-2 bg-grey"}>
                        <Col md={8}>
                            <Row className="d-flex align-items-center">
                                <Col md={6}>
                                    <Form.Label className="text-label">If applicable when did the client last take their medication?</Form.Label>
                                </Col>
                                <Col md={6}>
                                    <Form.Control max={moment().format('YYYY-MM-DD').toString().trim()} type="date" value={this.state.medicationDate} name={"medicationDate"} onChange={(e) => this.onChange(e)} className="form-field-border custom-width" />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control type="time" value={this.state.medicationTime} name={"medicationTime"} disabled={this.props.disabled} onChange={(e) => this.onChange(e)} className="form-field-border custom-width" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <Row className="d-flex align-items-center pl-0">
                        <Col md={12}>
                            <div className="client-record">
                                <h6>GENERAL OBSERVATIONS</h6>
                            </div>
                        </Col>
                    </Row>

                    <Row className="bg-grey d-flex align-items-center p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">
                                        Is the client currently showing any signs of aggression e.g.
                                        spitting, swearing, hitting, punching?
                                    </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control key={Math.random()} checked={!this.state.isAggression} disabled={this.props.disabled} value={false} onChange={(e) => this.onChange(e, 'check')} name="isAggression" type="radio" id="community-checkbox59" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox59">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control key={Math.random()} checked={this.state.isAggression} disabled={this.props.disabled} value={true} onChange={(e) => this.onChange(e, 'check')} name="isAggression" type="radio" id="community-checkbox60" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox60">
                                        Yes. If yes, don not approach the client - First assess whether
                                        a medical and/or police response is required.
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {this.state.risks.map((risk, index) => (
                        <Row key={"medicalRisk" + index} className="d-flex align-items-center p-2">
                            <Col md={4}>
                                <Row className="pl-2">
                                    {index === 0 && <Form.Label className="text-label">Does the client have any of the following symptoms?</Form.Label>}
                                </Row>
                            </Col>
                            <Col md={8}>

                                <Row>
                                    <Col md={12}>
                                        <Row className="d-flex align-items-center pl-3">
                                            <Col md={6}>
                                                <Select className="selection-select arrow-down custom-width font-bold"
                                                    placeholder="Cannot sit, stand or walk properly"
                                                    options={[
                                                        ...this.options2.filter(option2 => !this.state.risks.map(innerRisk => { return innerRisk.symptoms }).includes(option2.value))
                                                    ]}
                                                    values={[...this.options2.filter(elem => elem.value == (eval(risk.symptoms) ? eval(risk.symptoms) : ''))]}
                                                    name="symptoms"
                                                    onChange={(e) => this.handleDropDown(e, 'symptoms', index)}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <div className="btn-sec full-width text-left form-btn">
                                                    {index == (this.state.risks.length - 1) &&
                                                        <button disabled={!this.state.risks[index]['symptoms']} type="button" onClick={this.addMore} className="main-btn default-btn btn-width symtoms-btn btn btn-primary">
                                                            ADD MORE
                                                        </button>
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                {this.state.risks.length > 1 && <CancelIcon onClick={() => this.removeMedical(index)} className="float-right"></CancelIcon>}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ))
                    }
                </Col>
            </>
        )
    }
}

const stateMap = (state) => {
    return {
        isMedicalObservation: state.medicalObservation.isMedicalObservation,
        medicalObservations: state.medicalObservation.medicalObservations,
    };
};

export default connect(stateMap)(ClientMedicalRiskForm);