import React from 'react';
import { Row, Col } from "react-bootstrap";
import CustomForm from 'react-validation/build/form';
import "./category.scss";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import { connect } from "react-redux";
import store from '../../store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CategoryForm from "./categoryForm"
import { GetCategoryAction, UpdateCategoryAction } from '../../actions/category-action';

class Category extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.state = {
      startDate: null,
      endDate: null,
      categories: [],
      category: {},
      key: 'categories'
    }

  }
  componentDidMount() {
    store.dispatch(GetCategoryAction());
  }
  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      this.setState({ categories: this.props.categories, key: 'categories' });
      this.handleOnSelect('categories');
    }

    if (JSON.stringify(prevProps.category) !== JSON.stringify(this.props.category)) {
      store.dispatch(GetCategoryAction());
    }
  }

  componentWillUnmount() {
    // store.dispatch({ type: REPORT_DATA, payload: { data: '', start: new Date(), end: new Date() } })
  }
  
  insertItem(array, action) {
    return [
      ...array.slice(0),
      action,
      ...array.slice(0, 0),
    ]
  }

  handleChange = (e, params) => {
    let index = this.state.categories.findIndex(elem => elem.id === params.id);
    let category = params
    category[e.target.name] = e.target.checked;
    let temp = this.state.categories
    temp[index] = category
    this.setState({ categories: [...temp] });
    store.dispatch(UpdateCategoryAction(category));
  };

  handleOnSelect = (key) => {
    if (key === "categories") {
      this.setState({ category: {}, key: key })
    } else {
      this.setState({ key: key })
    }
  };
  render() {

    return (
      <div className="report-form">
        <div className="tabs-section full-width mt-4">
          <Tabs activeKey={this.state.key} onSelect={(k) => this.handleOnSelect(k)} id="controlled-tab-example">
            <Tab eventKey="categories" title={<span>Categories</span>} className="tab-table">
              {this.state.key === "categories" && <Row>
                <Col md={12}>
                  <CustomForm>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ cursor: 'pointer' }} align="center">Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {this.state.categories && this.state.categories.map((elem, index) => (

                            <TableRow style={{ cursor: 'pointer' }} key={"Category" + index}>
                              <TableCell align="center" onClick={() => this.setState({ category: elem, key: 'AddNew' })} className={index % 2 === 0 ? "bg-grey" : ""}>
                                {elem.name}
                              </TableCell>
                            </TableRow>
                          ))}

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CustomForm>
                </Col>
              </Row>}
            </Tab>
            <Tab eventKey="AddNew" title={<span>{this.state.category.name ? this.state.category.name : "Add New"}</span>} className="tab-table">
              {this.state.key === "AddNew" && <CategoryForm category={this.state.category} />}
            </Tab>
          </Tabs>
        </div>
      </div>
    )
  }
}
const stateMap = (state) => {
  return {
    categories: (state.category && state.category.categories || []),
    category: (state.category && state.category.category || {})
  };
};

export default connect(stateMap)(Category);