import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { DOMESTIC_VIOLANCE, IS_DOMESTIC_VIOLANCE } from "../../helpers/constants";
import CharacterCounter from '../../containers/character-counter/character-counter';

import CancelIcon from '@material-ui/icons/Cancel';

import "../diversion-centre/diversion-centre.scss";
import { connect } from "react-redux";
import store from '../../store';


class DomesticViolanceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isViolence: false,
            violances: [{
                violancerName: ''
            }]
        };
        this.addMore = this.addMore.bind(this);
        this.removeMedical = this.removeMedical.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e, loc, id) {
        let name = e.target.name;
        let stateData = this.state;
        if (loc === 'check') {
            this.props.onChange(e, loc)
            stateData.isViolence = eval(e.target.value)
            this.setState({ ...stateData })
        } else {
            stateData.violances[id].violancerName = e.target.value
            this.setState({ ...stateData }, () => {
                store.dispatch({
                    type: DOMESTIC_VIOLANCE,
                    payload: this.state.violances
                });
                store.dispatch({
                    type: IS_DOMESTIC_VIOLANCE,
                    payload: true
                });
            })
        }
    }

    addMore() {
        let violancesObjs = this.state.violances
        violancesObjs.push({
            violancerName: '',
        })
        this.setState({ violances: violancesObjs })
    }

    removeMedical(index) {
        let violances = this.state.violances
        violances.splice(index, 1);
        this.setState({ violances })
    }

    componentDidMount() {
        if (this.props.patrol) {
            let allProps = this.props.patrol;

            const domesticViolences = (allProps.DomesiticViolances && allProps.DomesiticViolances.length) ? allProps.DomesiticViolances : [{ violancerName: '' }];

            domesticViolences.forEach(dv => dv.violancerName = dv.violancerName || '');

            this.setState({
                isViolence: allProps.isViolence,
                violances: domesticViolences
            }, () => {
                store.dispatch({
                    type: DOMESTIC_VIOLANCE,
                    payload: this.state.violances
                });
                store.dispatch({
                    type: IS_DOMESTIC_VIOLANCE,
                    payload: true
                });

            })
        }
    }


    render() {
        return (
            <>
                <>
                    <Col md={6}>
                        <Row className="">
                            <Col md={8}>
                                <Form.Label className="text-label">Are there any "No contact" Domestic Violence Orders in place? </Form.Label>
                            </Col>
                            <Col md={2}>
                                <Form.Control name="isViolence" checked={!this.state.isViolence} value={false} onChange={(e) => this.onChange(e, 'check')} type="radio" id="community-checkbox55" className="custom-checkbox" />
                                <label htmlFor="community-checkbox55">No</label><br></br>
                            </Col>
                        </Row>
                    </Col>
                    <>
                        {this.state.violances.map((violance, index) => (
                            <React.Fragment key={"domesticViolence" + index}>
                                {index > 0 && <Col md={6} key={"spacer-" + index}></Col>}
                                <Col md={6} key={"dv-" + index}>
                                    <Row>
                                        <Col md={12}>
                                            <Row className={"d-flex align-items-center " + (index > 0 ? 'pt-2' : '')}>
                                                <Col md={3}>
                                                    {index == 0 && (<>
                                                        <Form.Control name="isViolence" checked={this.state.isViolence} value={true} onChange={(e) => this.onChange(e, 'check')} type="radio" id="community-checkbox56" className="custom-checkbox" />
                                                        <label htmlFor="community-checkbox56">Yes</label>
                                                    </>)}
                                                </Col>
                                                <Col md={5}>
                                                    <CharacterCounter>
                                                        <Form.Control disabled={!this.state.isViolence} value={violance.violancerName} onChange={(e) => this.onChange(e, 'name', index)} name="violancerName" className="form-fields pt-0" placeholder="Contact Name" maxLength={255} />
                                                    </CharacterCounter>
                                                </Col>
                                                <Col md={4}>
                                                    {this.state.violances.length > 1 && <CancelIcon onClick={() => this.removeMedical(index)} className="float-right"></CancelIcon>}
                                                    {index == (this.state.violances.length - 1) &&
                                                        <button disabled={!this.state.violances[index]['violancerName'] || !this.state.isViolence} type="button" onClick={this.addMore} className="main-btn default-btn btn btn-primary btn-sm mr-0">
                                                            ADD MORE
                                                        </button>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </React.Fragment>
                        ))}
                    </>
                </>
            </>
        )
    }
}

const stateMap = (state) => {
    return {
        isDomesticViolance: state.domesticViolance.isDomesticViolance,
        domesticViolance: state.domesticViolance.domesticViolance
    };
};

export default connect(stateMap)(DomesticViolanceForm);