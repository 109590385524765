import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import store from "../../store";
import Radio from '@material-ui/core/Radio';
import "./table.scss";
import { TO_CREATE_CLIENTS, DATE_FORMAT, SEARCH_RESULT, SEARCH_CLIENT_RES } from '../../helpers/constants';
import Moment from 'react-moment';
import moment from "moment"
import parsePhoneNumber from 'libphonenumber-js'
import { SearchClientsAction } from '../../actions/client-action';
import { connect } from "react-redux";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable(props) {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(0);
  var [toggle, setToggle] = useState(1);
  var [clients, setClients] = useState();
  var [page, setPage] = React.useState(0);
  var [lastSearch, setLastSearch] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  useEffect(() => {
    if (props.searchClients.length > 0)
      setSelectedValue(props.searchClients[0].id)
      setClients(props.searchClients)
  }, [props.searchClients])
  const handleChange = (event) => {
    
    setSelectedValue(event.target.value);
  };
  

  const handleChangePage = (event, newPage) => {
    
    store.dispatch(SearchClientsAction(`from=diversion&query=`+props.search+`&page=`+Number(newPage+1)))
    setPage(newPage);
  };

  const sortBy = (order,by) => {
    
    
    order = lastSearch != by ? 1 : !order
    clients.sort(function(a, b) {
      var keyA = a[by], keyB = b[by];
      if(keyA && keyB) return order  ? keyA.localeCompare(keyB) : keyB.localeCompare(keyA)
      // if (keyA < keyB) return -1;
      // if (keyA > keyB) return 1;
      // return 0;
    })
    store.dispatch({
      type: SEARCH_CLIENT_RES,
      payload: clients
    });
    setLastSearch(by)
    setToggle(!toggle)
  }

  return (
    <div className="table-content mt-3 mb-3">
      <div className="client-data">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                  sortBy(toggle,'firstName')
                }}>First Name</TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                  sortBy(toggle,'lastName')
                }}>Last Name</TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                  sortBy(toggle,'phone')
                }}>Mobile</TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                  sortBy(toggle,'dob')
                }}>DOB</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.searchClients && props.searchClients.map((elem, index) => (
                <TableRow style={{ cursor: 'pointer' }} onClick={() => {
                  props.history.push(`${TO_CREATE_CLIENTS}/${elem.id}`);
                  store.dispatch({
                    type: SEARCH_RESULT,
                    payload: false
                  });
                }}>
                  <TableCell align="center" className={index % 2 === 0 ? "bg-grey" : ""}>
                    {elem.firstName}
                  </TableCell>
                  <TableCell align="center" className={index % 2 === 0 ? "bg-grey" : ""}>
                    {elem.lastName}
                  </TableCell>
                  <TableCell align="center" className={index % 2 === 0 ? "bg-grey" : ""}>
                    {elem.phone ? parsePhoneNumber(elem.phone) ? parsePhoneNumber(elem.phone).formatNational() : elem.phone : 'N/A'}
                  </TableCell>
                  <TableCell align="center" className={index % 2 === 0 ? "bg-grey" : ""}>
                    {elem.dob ? <Moment format={DATE_FORMAT}>{moment(elem.dob)}</Moment> : 'Unknown'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // rowsPerPageOptions={[5, 10, 20]}
          labelRowsPerPage=''
          rowsPerPageOptions={[]}
          component="div"
          count={props.totalClients}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </div>
      <div className="btn-sec">
        {/* <Button variant="primary" type="button" onClick={() => props.history.push(`${TO_CREATE_CLIENTS}/${selectedValue}`)} className="main-btn orange-btn btn-right">
          View Client
              </Button> */}
        {/* <Button variant="primary" type="submit" onClick={() => {
          store.dispatch({
            type: SEARCH_RESULT,
            payload: false
          });
          props.history.push(TO_CREATE_CLIENTS)
        }} className="main-btn default-btn btn-right">
          Create Client
              </Button> */}
      </div>
    </div>
  );
}