import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import ProfileForm from "../../components/profile/profile";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";

export default class MainProfileContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN
        }
    }
    render() {
        return (
            <div className="admin-content" style={{ display: 'flex' }}>
                <Header isSideBarHide={true} handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                {/* <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}> */}
                    <ProfileForm {...this.props} />
                {/* </Sidebar> */}
            </div>
        )
    }
}
