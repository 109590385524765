import React from "react";
import { LoginAction } from "../../../actions/user/login";
import store from "../../../store"
import "./login-form.scss";
import { connect } from 'react-redux';
import { IN_PROCESS, TO_DASHBOARD, TO_RESET_PASS, TO_VERIFY, ALLOW_TO_VERIFY } from "../../../helpers/constants";
import { toast, setToken, setUser, getToken } from "../../../helpers/utility";
import { email, required } from '../../../helpers/form-validation'
import CustomForm from 'react-validation/build/form';
import Form from 'react-validation/build/form';
import { Button, Row, Col, Media } from "react-bootstrap";
import { TextField } from '@material-ui/core';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { compose } from "redux";
var qs = require('qs');

const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#A91F2E',
            fontSize: '0.7365728900255755rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: '#A91F2E',
            fontSize: '0.7365728900255755rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: '0.5728900255754475rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        }
    }
});

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.state = {
            user: {
                email: '',
                password: ''
            },
            redirect: ''
        }
    }
    handleChange(e) {
        let user = this.state.user
        user[e.target.name] = e.target.value;
        this.setState({ user })
    }
    handleOnSubmit(e) {
        e.preventDefault();
        // this.formData.validateAll()
        // if (this.formData.getChildContext()._errors.length === 0) {
        store.dispatch({ type: IN_PROCESS, payload: true });
        store.dispatch(LoginAction(this.state.user)) //set in session storage
        // }
    };
    componentDidMount() {
        let queryString = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true })
        // 
        if (Object.keys(queryString).length)
            this.setState({ redirect: queryString.redirect })
        if (this.props.user.isLoggedIn) {
            // if (queryString.redirect) {
            // this.props.history.push(queryString.redirect)
            // } else {
            
            store.dispatch({
                type: ALLOW_TO_VERIFY,
                payload: true
            })
            this.props.history.push(TO_VERIFY)
            // }
        }
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.user) !== JSON.stringify(prevProps.user))
            if (this.props.user.isLoggedIn !== prevProps.user.isLoggedIn && this.props.user.isLoggedIn) {
                toast()
                // 
                // setToken(this.props.user.user.token)
                // setUser(this.props.user.user.user)
                // window.location.reload();
                
                store.dispatch({
                    type: ALLOW_TO_VERIFY,
                    payload: true
                })
                this.props.history.push(TO_VERIFY)
            } else if (this.props.user.isLoggedIn === false && this.props.user.loginMessage) {
                toast(this.props.user.loginMessage, 'error')
            }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className="login-form">
                <div className="container">
                    <Row className="align-items-center content-row">
                        <Col md={6} sm={12} xs={12} className="">
                            <img
                                className="mb-4 logo-img"
                                src={require("../../../assets/images/logo.svg")}
                                alt="logo"
                            />
                        </Col>
                        <Col md={6}>
                            <div className="register-box">
                                <h3 className="text-center">LOGIN</h3>
                                {/* <Form ref={c => { this.form = c }} onSubmit={this.handleOnSubmit}> */}
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleOnSubmit}
                                    onError={errors => console.log(errors)}
                                >
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <TextValidator
                                                label="Email"
                                                // onChange={this.handleChange}
                                                // name="email"
                                                // value={email}
                                                variant="outlined"
                                                value={this.state.user.email}
                                                name="email"
                                                onChange={this.handleChange}
                                                fullWidth
                                                className={classes.root} id="outlined-basic"
                                                validators={['required', 'isEmail']}
                                                errorMessages={['this field is required', 'email is not valid']}
                                            />
                                            {/* <TextField className={classes.root} id="outlined-basic" validations={[required, email]} label="Email" variant="outlined" value={this.state.user.email} name="email" onChange={this.handleChange} fullWidth /> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <TextValidator
                                                label="Password"
                                                className={classes.root}
                                                onChange={this.handleChange}
                                                id="outlined-adornment-password"
                                                variant="outlined"
                                                name="password"
                                                type="password"
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                value={this.state.user.password}
                                                fullWidth
                                            />

                                            {/* <TextField type='password' validations={[required]} className={classes.root} id="outlined-adornment-password" label="Password" variant="outlined" value={this.state.user.password} name="password" onChange={this.handleChange} fullWidth /> */}
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col md={12} className="register-btn">
                                            {/* <Link to={TO_VERIFY}> */}
                                            <Button variant="primary" type="submit" size="lg" block>
                                                LOGIN
                                                </Button>
                                            {/* </Link> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center pt-4 color-alert" >
                                            <Link to={TO_RESET_PASS}>
                                                Forget Password?
                                            </Link>
                                        </Col>
                                    </Row>
                                </ValidatorForm>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        )
    }
}
const stateMap = (state) => {
    return {
        user: state.user,
        global: state.global,
    };
};

export default compose(connect(stateMap), withStyles(styles),)(LoginForm);
