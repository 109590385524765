import React from "react";
import "./logo.scss";
import { TO_DASHBOARD, TO_LOGIN, TO_REGISTER } from "../../helpers/constants";
import { Link } from "react-router-dom";


export default class Logo extends React.Component {
    render() {
        return (
            <div className="justify-content-sm-center align-items-center d-flex h-100">
                <div className="container">
                    <div className="mx-auto text-center">
                        <div className="container-fluid login">
                            <Link to={TO_DASHBOARD}>
                                <img
                                    className="mb-4 mt-5"
                                    src={require("../../assets/images/logo.svg")}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
