import React from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { WELLBIENG_OBSERVATION, IS_WELLBIENG_OBSERVATION } from "../../helpers/constants";

import CancelIcon from '@material-ui/icons/Cancel';

import CustomForm from 'react-validation/build/form';

import "../diversion-centre/diversion-centre.scss";
import { connect } from "react-redux";
import store from '../../store';


class ClientWellBeingForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAssistance: false,
            observations: [{
                behaviour: 0
            }]
        };
        this.addMore = this.addMore.bind(this);
        this.removeMedical = this.removeMedical.bind(this);
        this.onChange = this.onChange.bind(this);

        this.options3 = [
            { label: '', value: '', selected: true, disabled: true },
            { label: "1. Threatening or looking for ways to hurt or kill themselves", value: 1 },
            { label: "2. Talking or writing about death, dying or suicide", value: 2 },
            { label: "3. Giving away possessions or saying goodbye to family and/or friends, and/or saying they have no reason for living or have no purpose in life", value: 3 },
            { label: "4. Expressing feelings of hopelessness", value: 4 },
            { label: "5. Rage, extreme anger or expressions of revenge against a person", value: 5 },
            { label: "6. Engaging in reckless or risky behaviors", value: 6 },
            { label: "7. Anxious, agitated and/or expressing feelings of being trapped, like there’s no way out", value: 7 },
            { label: "8. Has discussed the increased use of alcohol or other drugs", value: 8 },
            { label: "9. Has discussed withdrawing from friends, family or the community", value: 9 },
            { label: "10. Has discussed abnormal sleep patterns – not sleeping or not being able to sleep without waking constantly", value: 10 },
            { label: "11. Dramatic changes in mood, such as feelings of happiness after a long period of sadness or depression", value: 11 },
        ];
    }

    handleDropDown(e, name, index) {
        let observations = this.state.observations
        if (e[0]) {
            observations[index][name] = e[0].value

            this.setState({ observations }, () => {
                store.dispatch({
                    type: WELLBIENG_OBSERVATION,
                    payload: this.state.observations
                });
                store.dispatch({
                    type: IS_WELLBIENG_OBSERVATION,
                    payload: true
                });
            })
        }
        // 
    }

    onChange(e, loc, id) {
        this.props.onFieldChange(e, loc)
        let name = e.target.name
        let stateData = this.state
        if (loc === 'check') {
            stateData[name] = eval(e.target.value)
            this.setState({ ...stateData })
        } else {
            stateData[name] = e.target.value
            this.setState({ ...stateData })
        }

    }

    addMore() {
        let observationsObjs = this.state.observations
        observationsObjs.push({
            behaviour: 0,
        })
        this.setState({ observations: observationsObjs })
    }

    removeMedical(index) {
        let observations = this.state.observations
        observations.splice(index, 1);
        this.setState({ observations })
    }

    componentDidMount() {
        if (this.props.medObs) {
            let allProps = this.props.medObs;
            this.setState({
                isAssistance: allProps.isAssistance,
                observations: (allProps.WellBeingObservations && allProps.WellBeingObservations.length) ? allProps.WellBeingObservations : [{ behaviour: '' }]
            }, () => {

                store.dispatch({
                    type: WELLBIENG_OBSERVATION,
                    payload: this.state.observations
                });
                store.dispatch({
                    type: IS_WELLBIENG_OBSERVATION,
                    payload: true
                });

            })
        }
    }


    render() {
        return (
            <>
                <Col md={12}>


                    <Row className="d-flex align-items-center p-2 bg-grey">
                        <Col md={12}>
                            <div className="client-record pl-0">
                                <h6>OBSERVATIONS OF CLIENT WELLBEING</h6>
                            </div>
                        </Col>
                    </Row>
                    {this.state.observations.map((observation, index) => (
                        <Row className="p-2" key={"Observation" + index}>
                            <Col md={4}>
                                <Row className="pl-2">
                                    {index === 0 && <Form.Label className="text-label">
                                        If you notice the client is acting strangely or
                                        particularly out of character it is important that
                                        you talk to them about it. Select any of the following
                                        behaviours shown by the client.
                                    </Form.Label>}
                                </Row>
                            </Col>
                            <Col md={8}>
                                <Row>
                                    <Col md={12}>
                                        <Row className="d-flex align-items-center pl-3">
                                            <Col md={6}>
                                                <Select className="selection-select arrow-down custom-width font-bold"
                                                    placeholder="Threatening or looking for ways to..."
                                                    options={[
                                                        ...this.options3.filter(option3 => !this.state.observations.map(observation => { return observation.behaviour }).includes(option3.value))
                                                    ]}
                                                    values={[...this.options3.filter(elem => elem.value == (eval(observation.behaviour) ? eval(observation.behaviour) : ''))]}
                                                    name="behaviour"
                                                    onChange={(e) => this.handleDropDown(e, 'behaviour', index)}
                                                />
                                            </Col>
                                            <Col md={2}>
                                                {this.state.observations.length > 1 && <CancelIcon onClick={() => this.removeMedical(index)} disabled={this.props.disabled} className="float-right"></CancelIcon>}
                                            </Col>
                                            <Col md={4}>
                                                <div className="btn-sec full-width text-left form-btn mt-2">
                                                    {index == (this.state.observations.length - 1) &&
                                                        <button disabled={!this.state.observations[index]['behaviour'] || this.props.disabled} type="button" onClick={this.addMore} className="main-btn default-btn btn btn-primary">
                                                            ADD MORE
                                                        </button>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ))
                    }

                </Col>
            </>
        )
    }
}

const stateMap = (state) => {
    return {
        isWellbiengObservation: state.wellbiengObservation.isWellbiengObservation,
        wellbiengObservations: state.wellbiengObservation.wellbiengObservations,
    };
};

export default connect(stateMap)(ClientWellBeingForm);