import React from 'react';
import { Row, Col } from "react-bootstrap";
import makeAnimated from 'react-select/animated';
import CustomForm from 'react-validation/build/form';
import "./location.scss";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import { connect } from "react-redux";
import store from '../../store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Switch from '@material-ui/core/Switch';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LocationForm from "./locationForm"
import { GetLocationAction, UpdateLocationAction } from '../../actions/location-action';

class Location extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.state = {
      startDate: null,
      endDate: null,
      locations: [],
      location: {},
      key: 'locations'
    }

  }
  componentDidMount() {

    store.dispatch(GetLocationAction());
  }
  componentDidUpdate(prevProps) {
    if (prevProps.locations !== this.props.locations) {
      this.setState({ locations: this.props.locations })
      console.log(this.props.locations)

    }
    if (prevProps.location !== this.props.location) {
      store.dispatch(GetLocationAction());

    }
  }
  componentWillUnmount() {
    // store.dispatch({ type: REPORT_DATA, payload: { data: '', start: new Date(), end: new Date() } })
  }
  insertItem(array, action) {
    return [
      ...array.slice(0),
      action,
      ...array.slice(0, 0),
    ]
  }
  handleChange = (e, params) => {
    let index = this.state.locations.findIndex(elem => elem.id === params.id);
    let location = params
    location[e.target.name] = e.target.checked;
    let temp = this.state.locations
    temp[index] = location
    this.setState({ locations: [...temp] });
    store.dispatch(UpdateLocationAction(location));
  };
  handleOnSelect = (key) => {
    if (key === "locations") {
      this.setState({ location: {}, key: key })
    } else {
      this.setState({ key: key })
    }
  };
  render() {
    const animatedComponents = makeAnimated();
    return (
      <div className="report-form">
        <div className="tabs-section full-width mt-4">
          <Tabs activeKey={this.state.key} onSelect={(k) => this.handleOnSelect(k)} id="controlled-tab-example">
            <Tab eventKey="locations" title={<span>Locations</span>} className="tab-table">
              {this.state.key === "locations" && <Row>
                <Col md={12}>
                  <CustomForm>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ cursor: 'pointer' }} align="center"
                            >Name</TableCell>
                            <TableCell style={{ cursor: 'pointer' }} align="center"
                            >Diversion Center</TableCell>
                            <TableCell style={{ cursor: 'pointer' }} align="center"
                            >Cell Visit</TableCell>
                            <TableCell style={{ cursor: 'pointer' }} align="center"
                            >Community Patrol</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {this.state.locations && this.state.locations.map((elem, index) => (

                            <TableRow style={{ cursor: 'pointer' }} key={"Location" + index}>
                              <TableCell align="center" onClick={() => this.setState({ location: elem, key: 'AddNew' })} className={index % 2 === 0 ? "bg-grey" : ""}>
                                {elem.name}
                              </TableCell>
                              <TableCell align="center" className={index % 2 === 0 ? "bg-grey" : ""}>
                                <Switch
                                  checked={elem.diversionCenter}
                                  onChange={(e) => this.handleChange(e, elem)}
                                  color="primary"
                                  name="diversionCenter"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </TableCell>
                              <TableCell align="center" className={index % 2 === 0 ? "bg-grey" : ""}>
                                <Switch
                                  checked={elem.cellVisit}
                                  onChange={(e) => this.handleChange(e, elem)}
                                  color="primary"
                                  name="cellVisit"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </TableCell>
                              <TableCell align="center" className={index % 2 === 0 ? "bg-grey" : ""}>
                                <Switch
                                  checked={elem.communityPatrol}
                                  onChange={(e) => this.handleChange(e, elem)}
                                  color="primary"
                                  name="communityPatrol"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CustomForm>
                </Col>
              </Row>}
            </Tab>
            <Tab eventKey="AddNew" title={<span>{this.state.location.name ? this.state.location.name : "Add New"}</span>} className="tab-table">
              {this.state.key === "AddNew" && < LocationForm location={this.state.location} />}
            </Tab>
          </Tabs>
        </div>
      </div>
    )
  }
}
const stateMap = (state) => {
  return {
    locations: state.location.locations,
    location: state.location.location
  };
};

export default connect(stateMap)(Location);