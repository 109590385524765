import React from "react";
import PasswordResetForm from "../../../components/user/password-reset/password-reset";

export default class PasswordResetContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="main-content">
          <PasswordResetForm history={this.props.history} />
        </div>
      </div>
    );
  }
}
