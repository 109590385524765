import React from "react";
import VerifyForm from "../../../components/user/verify-form/verify-form";

export default class verificationformContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="main-content">
          <VerifyForm {...this.props} />
        </div>
      </div>
    );
  }
}
