import React from "react";
import "./search.scss";
import { Button } from "react-bootstrap";
import { TextField } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';
import { TO_ADMIN } from "../../helpers/constants";

const styles = theme => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#A91F2E',
      fontSize: '0.7365728900255755rem',
      color: '#A91F2E',
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem"
      },
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: '#A91F2E',
      fontSize: '0.7365728900255755rem',
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem"
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: '0.5728900255754475rem',
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem"
      },
    }
  }
});
class RedButtonSearch extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className="Main-search search-red-btn mt-4">
        <TextField className={classes.root} onChange={this.props.onChange} id="outlined-search" placeholder='User Search' label="User Search" type="search" variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button variant="primary" onClick={() => this.props.history.push(TO_ADMIN)} type="submit" className="search-btn default-btn">
          Add New
        </Button>
      </div>

    )
  }
}

export default compose(withStyles(styles),)(RedButtonSearch);