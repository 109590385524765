import { DIVERSION_SEARCH_RESULT, DIVERSION_CLIENT, DIVERSION_TAB, DIVERSIONS, DIVERSION_SAVE } from "../helpers/constants";

export default function client(state = {
    clickedDiversionClients: {},
    isClickedDiversionClients: false,
    diversion: {},
    searchDiversionClients: [],
    diversions: [],
    diversionTab: {}
}, action) {
    switch (action.type) {
        case DIVERSION_CLIENT:
            {
                return { ...state, clickedDiversionClients: action.payload };
            }
        case DIVERSION_SEARCH_RESULT:
            {
                return { ...state, searchDiversionClients: action.payload };
            }
        case DIVERSION_TAB:
            {
                return { ...state, diversionTab: action.payload };
            }
        case DIVERSION_SAVE:
            {
                return { ...state, diversion: action.payload };
            }
        case DIVERSIONS:
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, diversions: action.payload }
                }
                return { ...state, diversions: [...state.diversions, action.payload] };
            }
        case 'IS_CLICKED_DIVERSION':
            {
                return { ...state, isClickedDiversionClients: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}