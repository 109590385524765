import React from "react";
import { ConfirmSignUpAction, RegisterAction } from "../../../actions/user/register";
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { Link } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
// import Button from '@material-ui/core/Button';
import { email, required, password, min8 } from '../../../helpers/form-validation'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from "../../../store"
import "./register-form.scss";
import { connect } from 'react-redux';
import { TO_LOGIN } from "../../../helpers/constants";
import { toast } from "../../../helpers/utility";
import { TextField } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import InputMask from 'react-input-mask'
import { confirmSignUp, verifyPasswordToken } from "../../../api/user/user-api";

const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#A91F2E',
            fontSize: '0.7365728900255755rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: '#A91F2E',
            fontSize: '0.7365728900255755rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: '0.5728900255754475rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        }
    }
});

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.state = {
            user: {
                email: '',
                // firstName: '',
                // lastName: '',
                password: '',
                phone: '',
                confirmPassword: '',
            }
        }
    }
    handleChange(e) {
        let user = this.state.user
        user[e.target.name] = e.target.value;
        this.setState({ user })
    }
    handleOnSubmit(e) {
        e.preventDefault();
        
        // this.form.validateAll()
        // if (this.form.getChildContext()._errors.length === 0) {
        store.dispatch(RegisterAction(this.state.user)) //set in session storage
        // } else {
        //     
        // }
    };
    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });
        store.dispatch(ConfirmSignUpAction(this.props.match.params.token))
    }
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.confirmSignUp) !== JSON.stringify(this.props.confirmSignUp)) {
            let user = this.props.confirmSignUp
            this.setState({ user })
        }
        if (this.props.user.isRegistered !== prevProps.user.isRegistered && this.props.user.isRegistered) {
            toast(this.props.user.registerMessage)
            this.props.history.push(TO_LOGIN)
        } else if (this.props.user.isRegistered !== prevProps.user.isRegistered && this.props.user.isRegistered === false) {
            toast(this.props.user.registerMessage, 'error')
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className="register-form">
                <div className="container">
                    <Row className="align-items-center content-row">
                        <Col md={6} className="">
                            <img
                                className="mb-4 logo-img"
                                src={require("../../../assets/images/logo.svg")}
                                alt="logo"
                            />
                        </Col>
                        <Col md={6}>
                            <div className="register-box">
                                <h3 className="text-center">REGISTER</h3>
                                <ValidatorForm
                                    ref={c => { this.form = c }}
                                    onSubmit={this.handleOnSubmit}
                                    onError={errors => console.log(errors)}
                                >
                                    {/* <Form ref={c => { this.form = c }} onSubmit={this.handleOnSubmit}> */}
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <TextValidator
                                                label="Email"
                                                // onChange={this.handleChange}
                                                // name="email"
                                                // value={email}
                                                variant="outlined"
                                                value={this.state.user.email}
                                                name="email"
                                                onChange={this.handleChange}
                                                disabled={true}
                                                fullWidth
                                                className={classes.root} id="outlined-basic"
                                                validators={['required', 'isEmail']}
                                                errorMessages={['this field is required', 'email is not valid']}
                                            />

                                            {/* <TextField className={classes.root} id="outlined-basic" validations={[required, email]} label="Email" variant="outlined" value={this.state.user.email} name="email" onChange={this.handleChange} fullWidth /> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            {/* <InputMask
                                                alwaysShowMask={false}
                                                className={classes.root}
                                                label="Phone"
                                                value={this.state.user.phone}
                                                name="phone"
                                                onChange={this.handleChange}
                                                type="text"
                                                fullWidth
                                                mask="99 9999 9999"
                                                maskChar="_"
                                            /> */}
                                            <InputMask mask="99 9999 9999" maskChar="_" value={this.state.user.phone} name="phone" onChange={this.handleChange}>
                                                {(inputProps) => <TextValidator
                                                    label="phone"
                                                    // onChange={this.handleChange}
                                                    // name="email"
                                                    // value={email}
                                                    variant="outlined"
                                                    // value={this.state.user.email}
                                                    name="phone"
                                                    {...inputProps}
                                                    // onChange={this.handleChange}
                                                    // disabled={true}
                                                    fullWidth
                                                    className={classes.root} id="outlined-basic"
                                                    // validators={['required']}
                                                    // errorMessages={['this field is required']}
                                                />}
                                            </InputMask>
                                            {/* <TextValidator
                                                label="Email"
                                                // onChange={this.handleChange}
                                                // name="email"
                                                // value={email}
                                                variant="outlined"
                                                value={this.state.user.email}
                                                name="email"
                                                onChange={this.handleChange}
                                                disabled={true}
                                                fullWidth
                                                className={classes.root} id="outlined-basic"
                                                validators={['required', 'isEmail']}
                                                errorMessages={['this field is required', 'email is not valid']}
                                            /> */}

                                            {/* <TextField className={classes.root} id="outlined-basic" validations={[required, email]} label="Email" variant="outlined" value={this.state.user.email} name="email" onChange={this.handleChange} fullWidth /> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <TextValidator
                                                label="Password"
                                                className={classes.root}
                                                onChange={this.handleChange}
                                                id="outlined-adornment-password"
                                                variant="outlined"
                                                name="password"
                                                type="password"
                                                validators={['required', 'minStringLength:8']}
                                                errorMessages={['Password must include (minimum 8 characters)']}
                                                value={this.state.user.password}
                                                fullWidth
                                            />
                                            {/* <TextField type='password' className={classes.root} validations={[min8, password]} id="outlined-adornment-password" label="Password" variant="outlined" value={this.state.user.password} name="password" onChange={this.handleChange} fullWidth /> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-2">
                                            <TextValidator
                                                label="Re-Enter Password"
                                                onChange={this.handleChange}
                                                id="outlined-adornment-password"
                                                className={classes.root}
                                                name="confirmPassword"
                                                type="password"
                                                validators={['isPasswordMatch', 'required']}
                                                errorMessages={['password mismatch', 'this field is required']}
                                                value={this.state.user.confirmPassword}
                                                variant="outlined"
                                                fullWidth
                                            />
                                            {/* <TextField type='password' className={classes.root} validations={[required]} id="outlined-adornment-password" label="Re-Enter Password" variant="outlined" value={this.state.user.confirmPassword} name="confirmPassword" onChange={this.handleChange} fullWidth /> */}
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col md={12} className="register-btn">
                                            <Button variant="primary" type="submit" size="lg" block>
                                                Register
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/* </Form> */}
                                </ValidatorForm>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }
}
const stateMapCreateEvent = (state) => {
    return {
        user: state.user,
        confirmSignUp: state.user.confirmSignUp,
    };
};
export default compose(connect(stateMapCreateEvent), withStyles(styles),)(RegisterForm);