import React, { useState, useEffect } from 'react';
import { Component, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { Row, Col } from "react-bootstrap";
import Paper from '@material-ui/core/Paper';
import RedButtonSearch from "../../components/search/search-red-button";
import { useHistory } from 'react-router-dom';
import { TO_ADMIN, LIST_USERS } from '../../helpers/constants';
import { GetSearchedUsersAction, GetUsersAction } from '../../actions/user/profile';
import store from '../../store';
import { connect } from 'react-redux';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function AdminUserTable(props) {
    const classes = useStyles();
    const history = useHistory();
    const [users, setUsers] = useState(props.users)
    var [toggle, setToggle] = useState(1);
    var [page, setPage] = React.useState(0);
    var [searchKey, setSearchKey] = React.useState('');
    var [lastSearch, setLastSearch] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    useEffect(() => {
        setUsers(props.users)
    }, [props.users]);

    useEffect(() => {
        setUsers(props.userSearch)
    }, [props.userSearch]);

    const onChange = (e) => {
        if (e.target.value.length > 2) {
            store.dispatch(GetUsersAction(`query=${e.target.value}&page=1`))
            setSearchKey(e.target.value)
        } else {
            store.dispatch(GetUsersAction(`query=&page=1`))
            // setUsers(props.users)
            setSearchKey('')
        }
    }

    const handleChangePage = (event, newPage) => {
        store.dispatch(GetUsersAction(`query=${searchKey}&page=${Number(newPage + 1)}`))
        setPage(newPage);
    };

    const toggleShowArchived = () => {
        window.showArchived = !window.showArchived;
        store.dispatch(GetUsersAction(`query=${searchKey}&page=${page + 1}&includeArchived=${!!window.showArchived}`))
    }

    const sortBy = (order, by) => {
        order = lastSearch != by ? true : order
        users.sort(function (a, b) {
            var keyA = a[by], keyB = b[by];
            if (keyA && keyB) return order ? keyA.localeCompare(keyB) : keyB.localeCompare(keyA)
            // if (keyA < keyB) return -1;
            // if (keyA > keyB) return 1;
            // return 0;
        })
        store.dispatch({
            type: LIST_USERS,
            payload: users
        });
        setLastSearch(by)
        setToggle(lastSearch != by ? order : !order)
    }

    return (
        <Fragment>
            <div className="search-red-btn">
                <RedButtonSearch {...props} onChange={onChange} from="admin" />
            </div>
            <div className="table-content create-user mt-3 mb-3">
                <div className="client-data">
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table" stripe>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                                        sortBy(toggle, 'firstName')
                                    }}>First Name</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                                        sortBy(toggle, 'lastName')
                                    }}>Last Name</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                                        sortBy(toggle, 'email')
                                    }}>Email</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                                        sortBy(toggle, 'role')
                                    }}>Role</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} align="center" onClick={() => {
                                        sortBy(toggle, 'location')
                                    }}>Location</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((elem, index) => (
                                    <TableRow style={{ cursor: 'pointer' }} onClick={() => history.push(TO_ADMIN + '/' + elem.id)} key={"Admin" + index}>
                                        <TableCell align="center" className={!(index % 2) ? 'bg-grey' : ''}>
                                            {elem.firstName}
                                        </TableCell>
                                        <TableCell align="center" className={!(index % 2) ? 'bg-grey' : ''}>
                                            {elem.lastName}
                                        </TableCell>
                                        <TableCell align="center" className={!(index % 2) ? 'bg-grey' : ''}>
                                            {elem.email}
                                        </TableCell>
                                        <TableCell align="center" className={!(index % 2) ? 'bg-grey' : ''}>
                                            {elem.role}
                                        </TableCell>
                                        <TableCell align="center" className={!(index % 2) ? 'bg-grey' : ''}>
                                            {elem.location}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        labelRowsPerPage=''
                        rowsPerPageOptions={[]}
                        component="div"
                        count={props.totalUsers}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                    <Row>
                        <Col md={12}>
                            <button type="button" className="main-btn default-btn btn btn-primary ml-3 save-btn mt-3 float-right" onClick={() => toggleShowArchived()}>
                                {window.showArchived ? 'Hide' : 'Show'} Archived
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
}
const stateMap = (state) => {
    return {
        userSearch: state.user.userSearch
    };
};
export default connect(stateMap)(AdminUserTable)
