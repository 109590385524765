import { CONTACTS_RES,TOTAL_CONTACTS, CREATE_CLIENT_RES, CREATE_CONTACT_RES, SEARCH_CLIENT_RES, SEARCH_RESULT } from '../helpers/constants';
import { createContact, getContact, getContactsList, updateContact } from '../api/contact-api';
require('es6-promise').polyfill();

export function CreateContactAction(data) {
    return async function (dispatch) {
        const res = await createContact(data)
        dispatch({
            type: CREATE_CONTACT_RES,
            payload: res.data.data.contact
        });
    };
}
export function GetContactsAction(data) {
    return async function (dispatch) {
        const res = await getContactsList(data)
        dispatch({
            type: CONTACTS_RES,
            payload: res.data.data.contacts
        });
        if(res.data.data.total>=0){
            dispatch({
                type: TOTAL_CONTACTS,
                payload: res.data.data.total
            });
        }
    };
}

export function updateContactAction(data) {
    return async function (dispatch) {
        const res = await updateContact(data)
        dispatch({
            type: CREATE_CONTACT_RES,
            payload: res.data.data.contact
        });
    };
}
export function getContactAction(id) {
    return async function (dispatch) {
        const res = await getContact(id)
        dispatch({
            type: CREATE_CONTACT_RES,
            payload: { ...res.data.data.contact, date: new Date().getTime() }
        });
    };
}



