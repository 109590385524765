import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'


import ActiveUserDiversion from "../../components/diversion-centre/active-user-community";
import ActiveUserCommunity from "../../components/community-patrol/active-user-community";
import CellVisitDashboard from "../../components/dashboard/cell-visit-dashboad";
import CommintyPortalDashboard from "../../components/dashboard/community-portal-dashboard";
import "./dashboard.scss";
import { getUser } from '../../helpers/utility';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 1
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(key) {
        
        this.setState({ key: key });
    }
    render() {

        return (
            <div className="dashboard-section full-width">
                <div className="tabs-section full-width mt-4">
                    <Tabs defaultActiveKey="past-activities" id="uncontrolled-tab-example">
                        <Tab eventKey="past-activities" title={<span>Diversion Centre</span>} className="tab-table">
                            <Row>
                                <Col md={12}>
                                    <ActiveUserDiversion fromDashboard={true} {...this.props} />
                                </Col>
                            </Row>
                        </Tab>
                        {getUser().Role && getUser().Role.cellVisits && <Tab eventKey="medical-obsevations" title={<span>Cell Visits</span>} className="tab-table comunity-user">
                            <Row>
                                <Col md={12}>
                                    <CellVisitDashboard fromDashboard={true} {...this.props} />
                                </Col>
                            </Row>
                        </Tab>}
                        <Tab eventKey="general-observations" title={<span>Community Patrol</span>} className="tab-table comunity-user">
                            <Row>
                                <Col md={12}>
                                    <ActiveUserCommunity fromDashboard={true} {...this.props} />
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </div>
            </div>

        )
    }
}

export default Dashboard;