import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import store from './store';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Notifications from 'react-notify-toast';
import { getToken } from "./helpers/utility";
import { IS_LOGIN, IS_ONLINE } from "./helpers/constants";
import { GetProfileAction } from "./actions/user/profile";
import { connect } from 'react-redux';
import { SearchClientsAction } from "./actions/client-action";
import { GetLocationAction } from "./actions/location-action";

//basic compnent of which holds all routes
class App extends Component {

    constructor(props) {
        super(props)
        this.updateOnlineStatus = this.updateOnlineStatus.bind(this)
        this.state = {
            data: [],
            show: false
        }
    }
    componentWillMount() {
        // if (ORIGIN.indexOf('localhost') > -1)
        //     this.socket = io.connect('http://localhost:8001', {
        //         secure: true,
        //         transports: ['websocket', 'polling'],
        //         rejectUnauthorized: false,
        //         path: '/socket.io'
        //     });
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    }
    componentDidMount() {
        if (getToken()) {
            store.dispatch({ type: IS_LOGIN, payload: true });
            store.dispatch(GetProfileAction());
            store.dispatch(SearchClientsAction(`from=diversion&query=&page=1`));
            store.dispatch(GetLocationAction());
            // this.initiatSocket()
        }
    }

    updateOnlineStatus(param) {
        store.dispatch({ type: IS_ONLINE, payload: param.type === 'online' });
    }

    render() {
        return (
            <div id="all" history={this.props.history}>
                <Notifications options={{ zIndex: 9999999, top: '0px' }} />
                <Routes socket={this.socket} />
                {/* { this.state.show && <ToastNotification notification={this.state.data} show={this.state.show} onClose={() => this.setState({ show: false })} />} */}
            </div>
        );
    }
}
const stateMap = (state) => {
    return {
        user: state.user.user,
    };
};
export default connect(stateMap)(withRouter(App));