import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import ClientViewDiversion from "../../components/clients-view/client-view-diversion";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";
import { getSingleDiversionCenter } from "../../actions/diversion-center";
import ClientViewTable from "./client-view"
import { connect } from "react-redux";
import store from '../../store';
class MainDiversionClientContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
            singleDiversion: {}
        }
    }
    componentDidMount(){
        
        if (this.props.match.params.id) {
            store.dispatch(getSingleDiversionCenter(this.props.match.params.id) )           
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.diversion !== this.props.diversion){
            this.setState({singleDiversion: this.props.diversion})
            
                      
        }
    }
    render() {
        return (
            <div className="admin-content" style={{ display: 'flex' }}>
                <Header handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
                    {/* {this.state.singleDiversion && <ClientViewTable client={this.state.singleDiversion.Client}/>} */}
                    <ClientViewDiversion stateDiversion={this.state.singleDiversion} {...this.props} />
                </Sidebar>
            </div>
        )
    }
}



const stateMap = (state) => {
    return {
        diversion: state.diversionCenter.diversion,
    };
};


export default connect(stateMap)(MainDiversionClientContainer);