import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Container, Row, Col, CardImg } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import "./header.scss";
import { getUser, logout } from '../../../helpers/utility';
import { TO_PROFILE } from "../../../helpers/constants";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    width: `100% `,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  menuButton: {
    marginRight: 36,
  },
}));


export default function MiniDrawer({ handleSidebar, history, isSideBarHide }) {
  const classes = useStyles();
  const handleDrawer = () => {
    handleSidebar()
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
        <Toolbar>
          <Container fluid>
            <Row className="d-flex align-items-center">
              <Col md={6} xs={6}>
                {
                  isSideBarHide ? <IconButton
                  color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawer}
                    edge="start"
                    className={clsx(classes.menuButton)}
                   onClick={() => { history.goBack() }} ><KeyboardBackspaceIcon/>
                   </IconButton> : <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawer}
                    edge="start"
                    className={clsx(classes.menuButton)}
                  >

                    <MenuIcon />
                  </IconButton>}
                <CardImg
                  src={require("../../../assets/images/logo.svg")}
                  alt="logo"
                  className="img-fluid"
                />
              </Col>
              <Col md={6} xs={6}>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className="header-logout">
                  <AccountCircleIcon />
                  {getUser().name}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >

                  <Link to={TO_PROFILE} >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                  </Link>
                  {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              </Col>
            </Row>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );
}
