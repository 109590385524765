import React from "react";
import "./search.scss";
import { Button } from "react-bootstrap";
import { TextField } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';
import { COMMUNITY_PATROL_TAB, DIVERSION_TAB, CELL_VISIT_TAB, IS_CLICKED_CELL, SEARCH_RESULT, TO_CREATE_CLIENTS } from "../../helpers/constants";
import { SearchClientsAction } from "../../actions/client-action";
import store from "../../store";

const styles = theme => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#A91F2E',
      fontSize: '0.7365728900255755rem',
      color: '#A91F2E',
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem"
      },
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: '#A91F2E',
      fontSize: '0.7365728900255755rem',
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem"
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: '0.5728900255754475rem',
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem"
      },
    }
  }
});
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.setTimeoutId = null;
    this.state = {
      search: ''
    }
  }

  handleChange(e) {
    this.setState({ search: e.target.value }, () => {
      clearTimeout(this.setTimeoutId)
      this.setTimeoutId = setTimeout(() => {
        this.onClick();
        this.props.searchChanged(this.state.search)
      }, 800)
    })
  }
  componentDidMount() {
    if (this.props.from && !this.props.match.params.id)
      store.dispatch(SearchClientsAction(`from=${this.props.from}&query=&page=1`, true)) //set in session storage
  }

  onClick() {
    clearTimeout(this.setTimeoutId)
    store.dispatch(SearchClientsAction(`from=${this.props.from}&query=${this.state.search}&page=1`)) //set in session storage
    store.dispatch({ type: 'IS_CLICKED_PATROL', payload: false });
    store.dispatch({ type: 'IS_CLICKED_DIVERSION', payload: false });
    store.dispatch({ type: IS_CLICKED_CELL, payload: false });
    store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: {} });
    store.dispatch({ type: DIVERSION_TAB, payload: {} });
    store.dispatch({ type: CELL_VISIT_TAB, payload: {} });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className="Main-search mt-4">
        <TextField className={classes.root} id="outlined-search"
          placeholder='Search Name, Mobile or DOB'
          label={this.props.from === 'client'?'Client Search':'Search'}
          value={this.props.search}
          onChange={(e) => this.handleChange(e)}
          onKeyPress={(e) => e.key === 'Enter' ? this.onClick() : console.log(e.key)}
          type="search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={this.onClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {this.props.from !== 'client' && <Button variant="primary" onClick={this.onClick} type="submit" className="search-btn">Search</Button>}
        {this.props.from === 'client' && <Button variant="primary" type="submit" style={{"padding" : "13px"}} onClick={() => {
          store.dispatch({
            type: SEARCH_RESULT,
            payload: false
          });
          this.props.history.push(TO_CREATE_CLIENTS)
        }} className="search-btn">
          Add New Client
              </Button>}
      </div>

    )
  }
}

export default compose(withStyles(styles))(Search);