import { getSystemReport, getSystemCellReport, getSystemCommunityReport, getSystemDiversionReport, getSystemCellReportDownload, getSystemDiversionReportDownload, getSystemCommunityReportDownload } from '../api/report-api';
import { REPORT_DATA, REPORT_DATA_CELL, REPORT_DATA_COMMUNITY, REPORT_DATA_DIVERSION, REPORT_DATA_CELL_DOWNLOAD, REPORT_DATA_COMMUNITY_DOWNLOAD, REPORT_DATA_DIVERSION_DOWNLOAD } from '../helpers/constants';

require('es6-promise').polyfill();

export function getSystemReportAction(data) {
    return async function (dispatch) {
        const res = await getSystemReport(data)
        dispatch({
            type: REPORT_DATA,
            payload: { data: res.data, start: data.startDate, end: data.endDate }
        });
    }
}

export function getCellReportAction(data) {
    return async function (dispatch) {
        const res = await getSystemCellReport(data)
        dispatch({
            type: REPORT_DATA_CELL,
            payload: { data: res.data, start: data.startDate, end: data.endDate }
        });
    }
}

export function getCellReportDownloadAction(data) {
    return async function (dispatch) {
        const res = await getSystemCellReportDownload(data)
        dispatch({
            type: REPORT_DATA_CELL_DOWNLOAD,
            payload: { data: res.data, start: data.startDate, end: data.endDate }
        });
    }
}

export function getCommunitySystemReportAction(data) {
    return async function (dispatch) {
        const res = await getSystemCommunityReport(data)
        dispatch({
            type: REPORT_DATA_COMMUNITY,
            payload: { data: res.data, start: data.startDate, end: data.endDate }
        });
    }
}

export function getCommunitySystemReportDownloadAction(data) {
    return async function (dispatch) {
        const res = await getSystemCommunityReportDownload(data)
        dispatch({
            type: REPORT_DATA_COMMUNITY_DOWNLOAD,
            payload: { data: res.data, start: data.startDate, end: data.endDate }
        });
    }
}

export function getDiversionSystemReportAction(data) {
    return async function (dispatch) {
        const res = await getSystemDiversionReport(data)
        dispatch({
            type: REPORT_DATA_DIVERSION,
            payload: { data: res.data, start: data.startDate, end: data.endDate }
        });
    }
}

export function getDiversionSystemReportDownloadAction(data) {
    return async function (dispatch) {
        const res = await getSystemDiversionReportDownload(data)
        dispatch({
            type: REPORT_DATA_DIVERSION_DOWNLOAD,
            payload: { data: res.data, start: data.startDate, end: data.endDate }
        });
    }
}
