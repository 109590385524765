import React from 'react';
import { Row, Col } from "react-bootstrap";
import CustomForm from 'react-validation/build/form';
import Form from '../shared/form-control'
import "./categoryForm.scss";
import { required } from '../../helpers/form-validation';
import { connect } from "react-redux";
import store from '../../store';
import { UpdateCategoryAction, CreateCategoryAction, ArchiveCategoryAction } from '../../actions/category-action';
import CharacterCounter from '../../containers/character-counter/character-counter';

class CategoryForm extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.state = {

            category: {
                name: ''
            }
        }

    }
    componentDidMount() {
        if (this.props.category && Object.keys(this.props.category).length > 0) {
            this.setState({ category: this.props.category })
        }
    }

    handleChange = (e, checked) => {
        let category = this.state.category;
        category[e.target.name] = e.target.value
        this.setState({ category })
    };

    archiveCategory = () => {
        if (this.state.category.id) {
            store.dispatch(ArchiveCategoryAction({ id: this.state.category.id }));
        }
    }

    handleOnSubmit(e) {
        e.preventDefault();
        this.form.validateAll();
        if (this.form.getChildContext()._errors.length === 0) {
            if (this.props.category && Object.keys(this.props.category).length > 0 && this.state.category.id) {
                store.dispatch(UpdateCategoryAction(this.state.category)); //set in session storage
            }
            else {
                store.dispatch(CreateCategoryAction(this.state.category)); //set in session storage
                this.setState({
                    category: {
                        name: ''
                    }
                });
            }
        }
    }

    render() {

        return (
            <div className="report-form">
                <Row>
                    <Col md={12}>
                        <CustomForm ref={c => { this.form = c }} autoComplete="false" onSubmit={this.handleOnSubmit}>
                            <Row className="report-section d-flex align-items-center p-1 bg-grey">
                                <Col>
                                    <Row className="d-flex align-items-center">
                                        <Col md={6}>
                                            <Form.Label className="text-label">
                                                Category
                                            </Form.Label>
                                        </Col>
                                        <Col md={6}>
                                            <CharacterCounter>
                                                <Form.Control
                                                    validations={[required]}
                                                    value={this.state.category.name}
                                                    name="name"
                                                    onChange={this.handleChange}
                                                    className="form-fields pt-0"
                                                    placeholder="Name"
                                                    maxLength={255} />
                                            </CharacterCounter>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <button type="submit" className="main-btn orange-btn btn btn-primary ml-3 save-btn mt-3 float-right">
                                Save
                            </button>
                            {this.state.category.id &&
                                <button type="button" className="main-btn default-btn btn btn-primary ml-3 save-btn mt-3 float-right" onClick={this.archiveCategory}>
                                    Archive
                                </button>
                            }
                        </CustomForm>
                    </Col>
                </Row>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {
        reportData: state.report.reportData,
        start: state.report.start,
        end: state.report.end
    };
};

export default connect(stateMap)(CategoryForm);