import React from "react";
import Category from "../../components/category/category";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";

import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";

export default class MainLocationContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN
        }
    }
    render() {
        return (
            <div className="admin-content" style={{ display: 'flex' }}>
                <Header handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
                    <Category {...this.props} />
                </Sidebar>
            </div>
        )
    }
}
