import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from 'react-dropdown-select';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import store from '../../store';
import { SearchClientsAction } from '../../actions/client-action';
import Moment from 'react-moment';
import moment from "moment"
import { DATE_FORMAT, TIME_FORMAT, LOCATION, SEARCH_CLIENT_RES } from '../../helpers/constants';

import "../cell-visits/cell-visits.scss";

class TableHeadDiversion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 1,
            stateDiversion: {},
            toggle: 1,
            page: 0,
            rowsPerPage: 20,
            lastSearch: '',
            clients: [],
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.changeToggle = this.changeToggle.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeLastSearch = this.changeLastSearch.bind(this);
        this.changePerPage = this.changePerPage.bind(this);
    }
    componentDidMount() {
        this.setState({ stateDiversion: { ...this.props.stateDiversion } })
        this.setState({ clients: this.props.searchClients })
    }
    componentDidUpdate(prevProps) {

        if (prevProps.stateDiversion !== this.props.stateDiversion) {
            this.setState({ stateDiversion: { ...this.props.stateDiversion } })
        } if (prevProps.searchClients !== this.props.searchClients) {
            this.setState({ clients: this.props.searchClients })
        }
    }

    handleSelect(key) {
        this.setState({ key: key });
    }
    changeToggle(v) {
        this.setState({ toggle: v })
    }
    changePage(v) {
        this.setState({ page: v })
    }
    changeLastSearch(v) {
        this.setState({ lastSearch: v })
    }
    changePerPage(v) {
        this.setState({ rowsPerPage: v })
    }

    handleChangePage(event, newPage) {
        store.dispatch(SearchClientsAction(`from=diversion&query=${this.props.search}&page=${Number(newPage + 1)}`))
        this.setState({ page: newPage });
    }

    sortBy(order, by) {
        order = this.state.lastSearch != by ? true : order
        let clients = this.state.clients;
        clients.sort(function (a, b) {
            if (by == 'dob' || by == 'timeIn') {
                if (by == 'dob')
                    var keyA = a[by] ? new Date(a[by]) : new Date(), keyB = b[by] ? new Date(b[by]) : new Date();
                else
                    var keyA = a.DiversionCenters[0] ? new Date(a.DiversionCenters[0][by]) : new Date(), keyB = b.DiversionCenters[0] ? new Date(b.DiversionCenters[0][by]) : new Date();
                if (order) {
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                } else {
                    if (keyB < keyA) return -1;
                    if (keyB > keyA) return 1;
                    return 0;
                }
            }
            else {
                var keyA = a[by], keyB = b[by];
                if (keyA && keyB) return order ? keyA.localeCompare(keyB) : keyB.localeCompare(keyA)
            }
        })
        store.dispatch({
            type: SEARCH_CLIENT_RES,
            payload: clients
        });
        this.changeLastSearch(by)
        this.changeToggle(this.state.lastSearch != by ? order : !order)
    }

    render() {
        return (
            <div className="table-head-community full-width">

                <TableContainer component={Paper}>
                    <Table className="text-center">
                        <TableHead>
                            <TableRow>
                                <TableCell className="text-center" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.sortBy(this.state.toggle, 'firstName')
                                }}>First Name</TableCell>
                                <TableCell className="text-center" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.sortBy(this.state.toggle, 'lastName')
                                }}>Last Name</TableCell>
                                <TableCell className="text-center" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.sortBy(this.state.toggle, 'phone')
                                }}>Mobile</TableCell>
                                <TableCell className="text-center" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.sortBy(this.state.toggle, 'dob')
                                }}>DOB</TableCell>
                                {!(!this.props.clientTab && !this.props.isOnlyHead) &&
                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => {
                                        this.sortBy(this.state.toggle, 'dropOff')
                                    }}>Location</TableCell>
                                }
                                <TableCell className="text-center" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.sortBy(this.state.toggle, 'timeIn')
                                }}>Time in</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {!this.props.clientTab && !this.props.isOnlyHead &&
                                (this.props.searchClients.map((client, index) =>
                                    <TableRow key={Math.random()} onClick={(e) => this.props.onRowSelect(e, client.id)}>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>{client.firstName}</TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>{client.lastName}</TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>{client.phone}</TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>
                                            {client.dob ? <Moment format={DATE_FORMAT}>{moment(client.dob)}</Moment> : 'N/A'}
                                        </TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>
                                            <Moment format={TIME_FORMAT}>{client.DiversionCenters && client.DiversionCenters.length > 0 ? moment(client.DiversionCenters[0].timeIn) : moment()}</Moment>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {!this.props.clientTab && this.props.isOnlyHead &&
                                (this.props.searchClients.filter(elem => elem.id === this.props.clientId).map((client, index) =>
                                    <TableRow key={Math.random()} >
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>{client.firstName}</TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>{client.lastName}</TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>{client.phone}</TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>
                                            {client.dob ? <Moment format={DATE_FORMAT}>{moment(client.dob)}</Moment> : 'N/A'}
                                        </TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>
                                            <Select className="selection-select arrow-down custom-width no-border"
                                                placeholder="Select Location"
                                                options={this.props.diversionLocations}
                                                values={[...this.props.diversionLocations.filter(elem => elem.value === (this.props.stateDiversion ? this.props.stateDiversion.locationId : ''))]}
                                                name="locationId"
                                                onChange={(e) => this.props.handleDropDown(e, 'locationId')}
                                            />
                                        </TableCell>
                                        <TableCell className={index % 2 === 0 ? "bg-grey text-center" : 'text-center'}>
                                            <Moment format={TIME_FORMAT}>{client.DiversionCenters && client.DiversionCenters.length > 0 ? moment(client.DiversionCenters[0].timeIn) : moment()}</Moment>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {this.props.clientTab &&
                                <TableRow>
                                    <TableCell className="text-center bg-grey">{this.props.stateDiversion.Client.firstName}</TableCell>
                                    <TableCell className="text-center bg-grey">{this.props.stateDiversion.Client.lastName}</TableCell>
                                    <TableCell className="text-center bg-grey">{this.props.stateDiversion.Client.phone}</TableCell>
                                    <TableCell className="text-center bg-grey">
                                        {this.props.stateDiversion.Client.dob ? <Moment format={DATE_FORMAT}>{moment(this.props.stateDiversion.Client.dob)}</Moment> : 'N/A'}
                                    </TableCell>
                                    <TableCell className="text-center bg-grey">
                                        <Select className="selection-select arrow-down custom-width no-border"
                                            placeholder="Select Location"
                                            options={this.props.diversionLocations}
                                            values={[...this.props.diversionLocations.filter(elem => elem.value === (this.props.stateDiversion ? this.props.stateDiversion.locationId : ''))]}
                                            name="locationId"
                                            onChange={(e) => this.props.handleDropDown(e, 'locationId')}
                                        />
                                    </TableCell>
                                    <TableCell className="text-center bg-grey">
                                        <Moment format={TIME_FORMAT}>{this.props.stateDiversion.Client.DiversionCenters && this.props.stateDiversion.Client.DiversionCenters.length > 0 ? moment(this.props.stateDiversion.Client.DiversionCenters[0].timeIn) : moment()}</Moment>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>

                </TableContainer>
                {!this.props.clientTab && !this.props.isOnlyHead && <TablePagination
                    // rowsPerPageOptions={[5, 10, 20]}
                    labelRowsPerPage=''
                    rowsPerPageOptions={[]}
                    component="div"
                    count={this.props.totalClients}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                />}
                {this.props.clientTab &&
                    <Row className="p-2 bg-grey">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">
                                        Address:
                                    </Form.Label>
                                </Col>

                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12} className="text-label">
                                    {this.props.stateDiversion.Client && this.props.stateDiversion.Client.address ? this.props.stateDiversion.Client.address : "N/A"}
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

export default TableHeadDiversion;