import React from 'react';
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment"
import "./clients-view.scss";
import { FULL_TIME_FORMAT } from '../../helpers/constants';
import ClientViewTable from "../../containers/clients-view/client-view"
import Moment from 'react-moment';
// import moment from "moment"
import { DATE_FORMAT, TIME_FORMAT, LOCATION } from '../../helpers/constants';


class ClientViewDiversion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false, cTime: '',
            isUpdate: false,
            diversion: {},
        };

        this.options2 = [
            { label: "1. Cannot sit, stand or walk properly", value: 1 },
            { label: "2. Unconscious and/or unable to be woken", value: 2 },
            { label: "3. Becoming more vague and less sensible over time", value: 3 },
            { label: "4. Serious injury, including head injury, bleeding in particular from the mouth or ears", value: 4 },
            { label: "5. Other signs of injuries such as cuts or bruises", value: 5 },
            { label: "6. Cannot stop vomiting, or vomits up blood", value: 6 },
            { label: "7. Epileptic fit or fainting", value: 7 },
            { label: "8. Asthma attack", value: 8 },
            { label: "9. Difficult or noisy breathing, including cracking or wheezing", value: 9 },
            { label: "10. Chest, head, stomach, leg, arm pain", value: 10 },
            { label: "11. Diarrhea", value: 11 },
            { label: "12. Showing signs of recently taking drugs and/or inhaled fumes", value: 12 },
            { label: "13. Withdrawal symptoms – especially from alcohol (anxious, aggressive, irritable, cannot sleep, tremor’s)", value: 13 },
        ];

        this.options3 = [
            { label: '', value: '', selected: true, disabled: true },
            { label: "1. Threatening or looking for ways to hurt or kill themselves", value: 1 },
            { label: "2. Talking or writing about death, dying or suicide", value: 2 },
            { label: "3. Giving away possessions or saying goodbye to family and/or friends, and/or saying they have no reason for living or have no purpose in life", value: 3 },
            { label: "4. Expressing feelings of hopelessness", value: 4 },
            { label: "5. Rage, extreme anger or expressions of revenge against a person", value: 5 },
            { label: "6. Engaging in reckless or risky behaviors", value: 6 },
            { label: "7. Anxious, agitated and/or expressing feelings of being trapped, like there’s no way out", value: 7 },
            { label: "8. Has discussed the increased use of alcohol or other drugs", value: 8 },
            { label: "9. Has discussed withdrawing from friends, family or the community", value: 9 },
            { label: "10. Has discussed abnormal sleep patterns – not sleeping or not being able to sleep without waking constantly", value: 10 },
            { label: "11. Dramatic changes in mood, such as feelings of happiness after a long period of sadness or depression", value: 11 },
        ];

        this.options4 = [
            { label: "Awake", value: 1 },
            { label: "Sleep", value: 2 },
        ];

        this.options5 = [
            { label: "Yes", value: 1 },
            { label: "No", value: 2 },
        ];
    }
    componentDidMount() {
        this.setState({ diversion: this.props.stateDiversion }, () => {

        })
    }
    componentDidUpdate(prevProps) {
        if (this.props.stateDiversion && prevProps.stateDiversion !== this.props.stateDiversion) {
            this.setState({ diversion: this.props.stateDiversion }, () => {

            })
        }
    }
    render() {
        return (
            <div className="clients-view full-width">
                <Row>
                    <Col md={12}>
                        <h4 className="text-center">
                            Diversion Centre
                        </h4>
                    </Col>
                </Row>
                <Row>
                    {this.props.stateDiversion && <ClientViewTable client={this.props.stateDiversion.Client} location={this.props.stateDiversion.Location} />}
                </Row>
                <Row className="p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">
                                    Has the client been involved in any altercations immediately prior
                                    to their arrival at the Diversion Centre?
                                </Form.Label>
                            </Col>
                            <Col md={3}>
                                {!this.state.diversion.isAlter && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.diversion.isAlter && <span className="sample-texts">Yes</span>}
                            </Col>
                            <Col md={12}>
                                <p className="sample-texts">{this.state.diversion.alterComment}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="bg-grey d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Does the client have any belongings on them (Including medication)? </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.diversion.isBelonging && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.diversion.isBelonging && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Does the client have any known medical conditions? </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.diversion.isMedical && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.diversion.isMedical && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Does an ambulance need to be contacted? </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.diversion.isAmbulance && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={6}>
                                {this.state.diversion.isAmbulance && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">
                                    Is the client on any medication?
                                </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.diversion.isOnMedication && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.diversion.isOnMedication && <span className="sample-texts">Yes</span>}
                            </Col>
                            <Col md={12}>
                                <p className="sample-texts">{this.state.diversion.medicationName}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Do arrangements need to be made for medication to be provided to the client?</Form.Label>
                            </Col>
                            <Col md={4}>
                                {(!this.state.diversion.isArrangements || this.state.diversion.isArrangements == 0) && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={4}>
                                {this.state.diversion.isArrangements == 1 && <span className="sample-texts">Unknown</span>}
                            </Col>
                            <Col md={8}>
                                {this.state.diversion.isArrangements == 2 && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={8}>
                        <Row className="d-flex align-items-center">
                            <Col md={6}>
                                <Form.Label className="text-label">If applicable when did the client last take their medication?</Form.Label>
                            </Col>
                            <Col md={6}>
                                <p className="sample-texts">{this.state.diversion.medicationDate && moment(this.state.diversion.medicationDate).format('YYYY-MM-DD')}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <Row>
                            <Col md={12}>
                                <p className="sample-texts">{this.state.diversion.medicationTime}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={12}>
                        <div className="client-record pl-0">
                            <h6>OBSERVATIONS OF CLIENT WELLBEING</h6>
                        </div>
                    </Col>
                </Row>
                <Row className="p-2">
                    <Col md={5}>
                        <Row className="pl-2">
                            <Form.Label className="text-label">
                                If you notice the client is acting strangely or
                                particularly out of character it is important that
                                you talk to them about it. Select any of the following
                                behaviours shown by the client.
                            </Form.Label>
                        </Row>
                    </Col>
                    <Col md={7}>
                        <Row>
                            <Col md={12}>
                                <Row className="d-flex align-items-center pl-3">
                                    <Col md={8}>
                                        <p className="sample-texts">
                                            {
                                                this.state.diversion &&
                                                this.state.diversion.WellBeingObservations &&
                                                this.state.diversion.WellBeingObservations.filter(obs => obs.behaviour && obs.behaviour.length).map((obs) => (
                                                    <p>
                                                        {
                                                            !eval(obs.behaviour) ? this.options3[eval(obs.behaviour)].label : this.options3[eval(obs.behaviour) - 1].label
                                                        }
                                                    </p>
                                                ))}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center pl-0 bg-grey">
                    <Col md={12}>
                        <div className="client-record">
                            <h6>File notes</h6>
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">
                                    Is the client currently showing any signs of aggression e.g.
                                    spitting, swearing, hitting, punching?
                                </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.diversion.isAggression && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                <Form.Control name="isAggression" type="radio" id="community-checkbox60" className="custom-checkbox" />
                                {this.state.diversion.isAggression && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={4}>
                        <Row className="pl-2">
                            <Form.Label className="text-label">Does the client have any of the following symptoms?</Form.Label>
                        </Row>
                    </Col>
                    <Col md={8}>
                        <Row>
                            <Col md={12}>
                                <Row className="d-flex align-items-center pl-3">
                                    <Col md={6}>

                                        <p className="sample-texts">
                                            {
                                                this.state.diversion &&
                                                this.state.diversion.MedicalObservations &&
                                                this.state.diversion.MedicalObservations.filter(obs => obs.symptoms && obs.symptoms.length).map((obs) => (
                                                    <p>{
                                                        !eval(obs.symptoms) ? this.options2[eval(obs.symptoms)].label : this.options2[eval(obs.symptoms) - 1].label
                                                    }</p>
                                                ))
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    {this.state.diversion && this.state.diversion.ClientObservationForms && this.state.diversion.ClientObservationForms.map((observation, index) => (
                        <>
                            <Col md={11}>
                                <div className="client-record">
                                    <h6>CLIENT OBSERVATION RECORD</h6>
                                    <Row className="d-flex align-items-center">
                                        <Col className="observation-colum">
                                            <Form.Label className="observation-label">Did you check on them?</Form.Label>
                                            <p className="form-fields observation-input input-content text-center pt-0 mb-0">
                                                {moment(observation.clientCheck).format(FULL_TIME_FORMAT).trim()}
                                            </p>
                                        </Col>
                                        <Col>
                                            <Form.Label className="observation-label">Were they awake or asleep?</Form.Label>
                                            <p className="form-fields observation-input input-content text-center pt-0 mb-0">
                                                {
                                                    this.options4[eval(observation.clientAwake) - 1].label
                                                }
                                            </p>
                                        </Col>
                                        <Col>
                                            <Form.Label className="observation-label text-center">Status Change?</Form.Label>
                                            <p className="form-fields observation-input input-content text-center pt-0 mb-0">
                                                {
                                                    this.options5[eval(observation.clientStatusChange) - 1].label
                                                }
                                            </p>
                                        </Col>
                                        <Col>
                                            <Form.Label className="observation-label d-block">Comments<br /><small>(Mandatory when status changed)</small></Form.Label>
                                            <p className="form-fields observation-input input-content text-center pt-0 mb-0">{observation.statusComments}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </>
                    ))
                    }
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={12}>
                        <div className="client-record pl-0">
                            <h6>CLIENT BACKGROUND INFORMATION</h6>
                        </div>
                    </Col>
                </Row>
                <Row className="pl-2">
                    <Col md={12}>
                        <div className="client-record pl-0">
                            <h6>CLIENT EXIT DETAILS</h6>
                        </div>
                    </Col>
                </Row>
                <Row className="p-2 bg-grey">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Has a referral been made on behalf of the client </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.diversion.isReferral && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                {this.state.diversion.isReferral && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="p-2 client-record">
                    <Col md={3}>
                        <Row>
                            <Col md={12}>
                                <Form.Label className="text-label">How did the client leave the service?</Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col md={12}>
                                {this.state.diversion.isLeave == 1 && <span for="sample-texts">Transported to a safe place (please advise the location)</span>}
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <p className="sample-texts">
                                        {this.state.diversion.leaveComment1}
                                    </p>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                {this.state.diversion.isLeave == 2 && <span for="sample-texts">Transported via Ambulance</span>}
                            </Col>
                            <Col md={12}>
                                {this.state.diversion.isLeave == 3 && <span for="csample-texts">Release from custody</span>}
                            </Col>
                            <Col md={12}>
                                {this.state.diversion.isLeave == 5 && <span htmlFor="sample-texts">Picked up by QPS</span>}
                            </Col>
                            <Col md={12}>
                                {this.state.diversion.isLeave == 4 && <span for="sample-texts">Other (please provide details)</span>}
                            </Col>
                            <Col md={12}>
                                <p className="sample-texts">
                                    {this.state.diversion.leaveComment2}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="p-2 bg-grey">
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Check In </Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                <span className="sample-texts"> <Moment format={DATE_FORMAT}>{moment(this.state.diversion.createdAt)}</Moment> <Moment format={TIME_FORMAT}>{moment(this.state.diversion.createdAt)}</Moment></span>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Check In By </Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                {this.state.diversion.createdDiversion && <span className="sample-texts"> {this.state.diversion.createdDiversion.firstName} {this.state.diversion.createdDiversion.lastName} </span>}
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row className="p-2">
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Check out </Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                {this.state.diversion.checkoutAt && <span className="sample-texts"> <Moment format={DATE_FORMAT}>{moment(this.state.diversion.checkoutAt)}</Moment> <Moment format={TIME_FORMAT}>{moment(this.state.diversion.checkoutAt)}</Moment></span>}
                                {!this.state.diversion.checkoutAt && <span className="sample-texts"> Still Checked In</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                {this.state.diversion.checkoutAt && <Form.Label className="text-label">Check out By </Form.Label>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                {this.state.diversion.checkoutAt && <span className="sample-texts"> {this.state.diversion.updatedDiversion.firstName} {this.state.diversion.updatedDiversion.lastName} </span>}
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>
        )
    }
}

export default ClientViewDiversion;