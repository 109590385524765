
import { http } from "./http";
import { CREATE_CONTACT } from "./constants";

export const createContact = async (data) => {
    return http.post(CREATE_CONTACT, data)
}
export const getContact = async (data) => {
    return http.get(CREATE_CONTACT + '/' +data)
}
export const getContactsList = async (data) => {
    return http.get(CREATE_CONTACT + '?' +data )
}
// export const searchContacts = async (data) => {
//     
//     return http.get(Contact_SEARCH + '?' + data)
// }
export const updateContact = async (data) => {
    return http.put(CREATE_CONTACT + '/' + data.id, data)
}
