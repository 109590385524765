import React from "react";
import Header from "../../components/shared/header/header";
import Search from "../../components/search/search";
import UpdateForm from "../../components/client-form/update-form";
import ThreeTabs from "../../components/three-tabs/three-tabs";
import Sidebar from "../../components/shared/sidebar/sidebar";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";
import BasicTable from "../../components/table/table";
import { connect } from "react-redux";

export class ClientsContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN
        }
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps) {
        if (prevProps.searchResult !== this.props.searchResult) {


        }
    }
    render() {
        return (
            <div className="client-content" style={{ display: 'flex' }}>
                <Header handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
                    {/* <Search from="client" {...this.props} /> */}
                    {/* <UpdateForm {...this.props} /> */}
                    {!this.props.searchResult ? (
                        <>
                            <UpdateForm {...this.props} />
                            <ThreeTabs {...this.props} />
                        </>
                    ) : (
                        <>
                            <UpdateForm {...this.props} />
                            <ThreeTabs {...this.props} />
                        </>
                    )}

                </Sidebar>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {

        searchClients: state.client.searchClients,
        searchResult: state.client.searchResult
    };
};
export default connect(stateMap)(ClientsContainer);
