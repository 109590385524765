import React from "react";
import ReportAllForm from "../../components/report-form/report-all-form";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";

import { DEFAULT_SIDEBAR_OPEN, REPORT_DATA_DIVERSION_DOWNLOAD } from "../../helpers/constants";
import store from "../../store";
import { getDiversionSystemReportAction, getDiversionSystemReportDownloadAction } from "../../actions/report-action";
import { connect } from "react-redux";
import { toast } from "../../helpers/utility";
import moment from 'moment';
import download from 'js-file-download';

class MainReportDiversionContainer extends React.Component {
    constructor(props) {
        super(props)
        this.generateReport = this.generateReport.bind()
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN
        }

        this.subscriptions = [];
        this.onDownloadReport();
    }
    generateReport(params) {
        store.dispatch(getDiversionSystemReportAction(params))
    }

    onDownloadReport() {
        // Trigger the download immediately after the data is known
        this.subscriptions.push(store.subscribe(evt => {
            const storeState = store.getState();
            if (storeState && storeState.global && storeState.global.lastAction === REPORT_DATA_DIVERSION_DOWNLOAD) {
                download(storeState.report.reportDataDiversionDownload, moment(this.props.start).format('DD-MM-YYYY') + '-' + moment(this.props.end).format('DD-MM-YYYY') + '-Report.csv')
            }
        }));
    }

    componentWillUnmount() {
        this.subscriptions.forEach( unsubscrube => unsubscrube() );
    }

    downloadReport() {
        if (!this.state.startDate || !this.state.endDate) {
            toast("Please select Start/End Date");
            return;
        }

        store.dispatch(getDiversionSystemReportDownloadAction(this.state));
    }

    render() {
        return (
            <div className="admin-content" style={{ display: 'flex' }}>
                <Header handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
                    <ReportAllForm title={"Report Diversion Center"} reportData={this.props.reportData} downloadReport={this.downloadReport} {...this.props} />
                </Sidebar>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {
        reportData: state.report.reportDataDiversion,
        start: state.report.start,
        end: state.report.end,
        locations: state.location.locations
    };
};

export default connect(stateMap)(MainReportDiversionContainer);