
// routes constants
export const TO_HOME = '/'
export const TO_LOGIN = '/login'
export const TO_SIGNUP = '/signup'
export const TO_REGISTER = '/register'
export const TO_RESET_PASS = '/reset-password'
export const TO_VERIFY = '/verify-thats-you'
export const TO_CONFIRM_SIGNUP = "/confirm";
export const TO_DASHBOARD = '/dashboard'
export const TO_CLIENTS = '/clients'
export const TO_ADMIN = '/admin'
export const TO_ADMIN_LIST = '/admin-list'
export const TO_COMMUNITY_PATROL = '/community-patrol'
export const TO_CONFIRM_CELLVISIT = '/confirm-cellVisit'
export const TO_CREATE_CLIENTS = '/create-clients'
export const TO_CELL_VISIT = '/cell-visits'
export const TO_CELL_VISIT_FORM = '/cell-visits-form'
export const TO_DIVERSION_CENTRE = '/diversion-centre'
export const TO_CONTACT = '/contact'
export const TO_PROFILE = '/profile'
export const TO_SERVICE_VIEW = '/service-view'
export const TO_REPORT_FORM = '/report-form'
export const TO_REPORT_DIVERSION = '/report-diversion'
export const TO_REPORT_CELLVISIT = '/report-cellvisit'
export const TO_REPORT_COMMUNITYPETROL = '/report-communitypetrol'
export const TO_LOCATION = '/location'
export const TO_CATEGORY = '/category';


//action constants
export const USER_RES = 'USER_RES'
export const LOGIN_RES = 'LOGIN_RES'
export const IS_LOGIN = 'IS_LOGIN'
export const IS_VERIFY = 'IS_VERIFY'
export const REGISTER_RES = 'REGISTER_RES'
export const RESET_USER = 'RESET_USER'
export const LOGIN_MESSAGE = 'LOGIN_MESSAGE'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const CONFIRM_SIGNUP_RES = 'CONFIRM_SIGNUP_RES'
export const IN_PROCESS = 'IN_PROCESS'
export const IS_ONLINE = 'IS_ONLINE'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_RES = 'RESET_PASSWORD_RES'
export const REPORT_DATA = 'REPORT_DATA'
export const REPORT_DATA_CELL = 'REPORT_DATA_CELL'
export const REPORT_DATA_CELL_DOWNLOAD = 'REPORT_DATA_CELL_DOWNLOAD'
export const REPORT_DATA_COMMUNITY = 'REPORT_DATA_COMMUNITY'
export const REPORT_DATA_COMMUNITY_DOWNLOAD = 'REPORT_DATA_COMMUNITY_DOWNLOAD'
export const REPORT_DATA_DIVERSION = 'REPORT_DATA_DIVERSION'
export const REPORT_DATA_DIVERSION_DOWNLOAD = 'REPORT_DATA_DIVERSION_DOWNLOAD'
export const CLIENT_NOTES = 'CLIENT_NOTES'
export const CLIENT_NOTE = 'CLIENT_NOTE'
export const CREATE_CLIENT_RES = 'CREATE_CLIENT_RES'
export const CREATE_CONTACT_RES = 'CREATE_CONTACT_RES'
export const TOTAL_CONTACTS = 'TOTAL_CONTACTS'
export const LOCATION_RES = 'LOCATION_RES'
export const CREATE_LOCATION_RES = 'CREATE_LOCATION_RES'
export const CATEGORY_RES = 'CATEGORY_RES';
export const CREATE_CATEGORY_RES = 'CREATE_CATEGORY_RES';
export const ARCHIVE_CATEGORY_RES = 'ARCHIVE_CATEGORY_RES';
export const CREATE_USER_RES = 'CREATE_USER_RES'
export const INVITE_USER = 'INVITE_USER'
export const ARCHIVE_USER_RES = 'ARCHIVE_USER_RES'
export const RESTORE_USER_RES = 'RESTORE_USER_RES'
export const CONTACTS_RES = 'CONTACTS_RES'
export const SEARCH_CLIENT_RES = 'SEARCH_CLIENT_RES'
export const LIST_CLIENT_RES = 'LIST_CLIENT_RES'
export const ALLOW_TO_VERIFY = 'ALLOW_TO_VERIFY'
export const SEARCH_RESULT = 'SEARCH_RESULT'
export const NEW_CLIENT_SEARCH = 'NEW_CLIENT_SEARCH'
export const CLIENT_UPDATED = 'CLIENT_UPDATED'
export const CLIENT_CREATED = 'CLIENT_CREATED'
export const PATROL_SEARCH_RESULT = 'PATROL_SEARCH_RESULT'
export const COMMUNITY_PATROL_SAVE = 'COMMUNITY_PATROL_SAVE'
export const COMMUNITY_PATROL_TAB = 'COMMUNITY_PATROL_TAB'
export const IS_CLICKED_PATROL = 'IS_CLICKED_PATROL'
export const PETROL_CLIENT = 'CLICKED_PATROL_CLIENT'
export const COMMUNITY_PATROLS = 'COMMUNITY_PATROLS'
export const CLICKED_INDEX = 'CLICKED_INDEX'
export const TOTAL_CLIENTS = 'TOTAL_CLIENTS'
export const TOTAL_USERS = 'TOTAL_USERS'
export const TOTAL_SEARCH_USERS = 'TOTAL_SEARCH_USERS'
export const USER_UPDATED = 'USER_UPDATED'
export const USER_ARCHIVED = 'USER_ARCHIVED'

export const CELL_SEARCH_RESULT = 'CELL_SEARCH_RESULT'
export const CELL_VISIT_SAVE = 'CELL_VISIT_SAVE'
export const CELL_VISIT_TAB = 'CELL_VISIT_TAB'
export const IS_CLICKED_CELL = 'IS_CLICKED_CELL'
export const CELL_CLIENT = 'CELL_CLIENT'
export const CELL_VISITS = 'CELL_VISITS'

export const DIVERSION_SEARCH_RESULT = 'DIVERSION_SEARCH_RESULT'
export const DIVERSION_SAVE = 'DIVERSION_SAVE'
export const DIVERSION_TAB = 'DIVERSION_TAB'
export const IS_CLICKED_DIVERSION = 'IS_CLICKED_DIVERSION'
export const DIVERSION_CLIENT = 'CLICKED_DIVERSION_CLIENT'
export const DIVERSIONS = 'DIVERSIONS'
export const LIST_USERS = 'LIST_USERS'


export const IS_MEDICAL_OBSERVATION = 'IS_MEDICAL_OBSERVATION'
export const MEDICAL_OBSERVATION = 'MEDICAL_OBSERVATION'

export const IS_WELLBIENG_OBSERVATION = 'IS_WELLBIENG_OBSERVATION'
export const WELLBIENG_OBSERVATION = 'WELLBIENG_OBSERVATION'

export const IS_CLIENT_OBSERVATION = 'IS_CLIENT_OBSERVATION'
export const CLIENT_OBSERVATION = 'CLIENT_OBSERVATION'

export const IS_DOMESTIC_VIOLANCE = 'IS_DOMESTIC_VIOLANCE'
export const DOMESTIC_VIOLANCE = 'DOMESTIC_VIOLANCE'

export const IS_CLIENT_BACKGROUND = 'IS_CLIENT_BACKGROUND'
export const CLIENT_BACKGROUND = 'CLIENT_BACKGROUND'



//site constants
export const TOAST_DURATION = 5000
export const ORIGIN = window.location.origin
export const TOKEN_COOKIE = 'WATCH_TOKEN'
export const USER_COOKIE = 'WATCH_USER'
export const COOKIE_EXPIRE = 60 * 60 * 24 * 365
export const PATH_COOKIE = '/'
export const DATE_FORMAT = 'DD/MM/YY'
export const TIME_FORMAT = 'hh:mm A'
export const FULL_TIME_FORMAT = 'HH:mm '
export const TIME_ZONE = "UTC"
export const DEFAULT_SIDEBAR_OPEN = true
export const RISK_FLAG_COLOR = '#ffce44'
export const FOOT_FLAG_COLOR = '#000'
export const DOMESTIC_FLAG_COLOR = '#f00025'
export const BOWMAN_JOHNSON_ADDRESS = '5 Oxford St, South Brisbane QLD 4101, Australia'

export const LOCATION = [
  { label: "Brisbane", value: 'Brisbane', name: 'location' },
  { label: "Bowman Johnson", value: 'BowmanJohnson', name: 'location' },
  { label: "Mackay", value: 'Mackay', name: 'location' },
  { label: "Townsville", value: 'Townsville', name: 'location' },
  { label: "Palm Island", value: 'PalmIsland', name: 'location' },
  
  { label: "Brisbane City", value: 'BrisbaneCity', name: 'location' },
  { label: "Ipswich", value: 'Ipswich', name: 'location' },
  { label: "Richlands", value: 'Richlands', name: 'location' },
  { label: "Beenleigh", value: 'Beenleigh', name: 'location' },
  { label: "Cleveland", value: 'Cleveland', name: 'location' },
  { label: "Redcliffe", value: 'Redcliffe', name: 'location' },
  { label: "Pine Rivers", value: 'PineRivers', name: 'location' },
  { label: "Caboolture", value: 'Caboolture', name: 'location' },
  { label: "Mareeba", value: 'Mareeba', name: 'location' },
  { label: "Innisfail", value: 'Innisfail', name: 'location' },
];

export const ROLE = [
  { label: "Admin", value: 'Admin', name: 'role' },
  { label: "Case Workers", value: 'CaseWorker', name: 'role' },
  { label: "Cell Visitors", value: 'CellVisitor', name: 'role' },
  { label: "Support Officers", value: 'SupportOfficer', name: 'role' },
];

export const CLIENT_ACCESS = [
  { label: "1-Community Patrol has referred", value: "1-Community Patrol has referred", name: "accessComment", },
  { label: "2-Family or friends contacted", value: "2-Family or friends contacted", name: "accessComment", },
  { label: "3-Queensland Police Service contacted", value: "3-Queensland Police Service contacted", name: "accessComment", },
  { label: "4-Service User has self-referred", value: "4-Service User has self-referred", name: "accessComment", },
  { label: "99-Other", value: "99-Other", name: "accessComment", },
];

